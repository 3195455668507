import { openApiSlice as api } from "./openApiSlice";
const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Users', 'Students', 'Absences', 'AttendanceTimes', 'Quota', 'Groups'] });
const injectedRtkApi = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    platformGet: build.query<PlatformGetApiResponse, PlatformGetApiArg>({
      query: () => ({ url: `/` }),
    }),
    loginLoginPost: build.mutation<
      LoginLoginPostApiResponse,
      LoginLoginPostApiArg
    >({
      query: (queryArg) => ({
        url: `/login`,
        method: "POST",
        body: queryArg.login,
      }),
    }),
    getPermissionGenPermissionGet: build.query<
      GetPermissionGenPermissionGetApiResponse,
      GetPermissionGenPermissionGetApiArg
    >({
      query: (queryArg) => ({
        url: `/gen/permission`,
        params: { token: queryArg.token },
      }),
    }),
    instituteNameGenNameGet: build.query<
      InstituteNameGenNameGetApiResponse,
      InstituteNameGenNameGetApiArg
    >({
      query: (queryArg) => ({
        url: `/gen/name`,
        params: { token: queryArg.token },
      }),
    }),
    instituteLogoGenLogoPngGet: build.query<
      InstituteLogoGenLogoPngGetApiResponse,
      InstituteLogoGenLogoPngGetApiArg
    >({
      query: (queryArg) => ({
        url: `/gen/logo.png`,
        params: { token: queryArg.token },
      }),
    }),
    getQuotaGenQuotaGet: build.query<
      GetQuotaGenQuotaGetApiResponse,
      GetQuotaGenQuotaGetApiArg
    >({
      query: (queryArg) => ({
        url: `/gen/quota`,
        params: { token: queryArg.token, trustee: queryArg.trustee },
      }),
      providesTags: ['Quota'],
    }),
    groupsNamesGenGroupsGet: build.query<
      GroupsNamesGenGroupsGetApiResponse,
      GroupsNamesGenGroupsGetApiArg
    >({
      query: (queryArg) => ({
        url: `/gen/groups`,
        params: { token: queryArg.token },
      }),
    }),
    currentAbsencesAbsenceSearchNowGet: build.query<
      CurrentAbsencesAbsenceSearchNowGetApiResponse,
      CurrentAbsencesAbsenceSearchNowGetApiArg
    >({
      query: (queryArg) => ({
        url: `/absence/search/now`,
        params: { token: queryArg.token },
      }),
    }),
    listAbsencesAbsenceSearchPeriodGet: build.query<
      ListAbsencesAbsenceSearchPeriodGetApiResponse,
      ListAbsencesAbsenceSearchPeriodGetApiArg
    >({
      query: (queryArg) => ({
        url: `/absence/search/period`,
        params: {
          token: queryArg.token,
          since: queryArg.since,
          to: queryArg.to,
        },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ ID }) => ({ type: 'Absences' as const, id: ID })),
            { type: 'Absences', id: 'LIST' },
          ]
          : [{ type: 'Absences', id: 'LIST' }],
    }),
    checkTimelineAbsenceCheckGet: build.query<
      CheckTimelineAbsenceCheckGetApiResponse,
      CheckTimelineAbsenceCheckGetApiArg
    >({
      query: (queryArg) => ({
        url: `/absence/check`,
        params: {
          token: queryArg.token,
          starting: queryArg.starting,
          ending: queryArg.ending,
          internal: queryArg.internal,
        },
      }),
    }),
    addAbsenceAbsenceAddPost: build.mutation<
      AddAbsenceAbsenceAddPostApiResponse,
      AddAbsenceAbsenceAddPostApiArg
    >({
      query: (queryArg) => ({
        url: `/absence/add`,
        method: "POST",
        params: {
          token: queryArg.token,
          student_id: queryArg.studentId,
          beginning: queryArg.beginning,
          ending: queryArg.ending,
        },
      }),
      invalidatesTags: [{ type: 'Absences', id: 'LIST' }],
    }),
    delAbsenceAbsenceDelPost: build.mutation<
      DelAbsenceAbsenceDelPostApiResponse,
      DelAbsenceAbsenceDelPostApiArg
    >({
      query: (queryArg) => ({
        url: `/absence/del`,
        method: "POST",
        params: {
          token: queryArg.token,
          absence_id: queryArg.absenceId,
          waiting_list: queryArg.waitingList,
        },
      }),
      invalidatesTags: [{ type: 'Absences', id: 'LIST' }],
    }),
    groupsListSettingGroupListGet: build.query<
      GroupsListSettingGroupListGetApiResponse,
      GroupsListSettingGroupListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/group/list`,
        params: { token: queryArg.token },
      }),
      providesTags: ['Groups'],
    }),
    editGroupSettingGroupEditPost: build.mutation<
      EditGroupSettingGroupEditPostApiResponse,
      EditGroupSettingGroupEditPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/group/edit`,
        method: "POST",
        body: queryArg.group,
        params: { token: queryArg.token },
      }),
      async onQueryStarted({ group: editedGroup, token }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('groupsListSettingGroupListGet', { token }, (draft) => {
            const currGroupIdx = draft.findIndex((g) => g.serial === editedGroup.serial);
            if (currGroupIdx === -1) {
              return draft;
            }
            draft[currGroupIdx] = editedGroup;
            return draft;
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    addGroupSettingGroupAddPost: build.mutation<
      AddGroupSettingGroupAddPostApiResponse,
      AddGroupSettingGroupAddPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/group/add`,
        method: "POST",
        params: { token: queryArg.token, nickname: queryArg.nickname },
      }),
      invalidatesTags: ['Groups'],
    }),
    delGroupSettingGroupDeletePost: build.mutation<
      DelGroupSettingGroupDeletePostApiResponse,
      DelGroupSettingGroupDeletePostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/group/delete`,
        method: "POST",
        params: { token: queryArg.token, serial: queryArg.serial },
      }),
      async onQueryStarted({ serial, token }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('groupsListSettingGroupListGet', { token }, (draft) => {
            const currGroupIdx = draft.findIndex((g) => g.serial === serial);
            if (currGroupIdx === -1) {
              return draft;
            }
            draft.splice(currGroupIdx, 1);
            return draft;
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getStudentsListSettingStudentsListGet: build.query<
      GetStudentsListSettingStudentsListGetApiResponse,
      GetStudentsListSettingStudentsListGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/students/list`,
        params: { token: queryArg.token },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ ID }) => ({ type: 'Students' as const, id: ID })),
            { type: 'Students', id: 'LIST' },
          ]
          : [{ type: 'Students', id: 'LIST' }],
    }),
    editStudentSettingStudentEditPost: build.mutation<
      EditStudentSettingStudentEditPostApiResponse,
      EditStudentSettingStudentEditPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/student/edit`,
        method: "POST",
        params: {
          token: queryArg.token,
          ID: queryArg.id,
          name: queryArg.name,
          phone_num: queryArg.phoneNum,
          group: queryArg.group,
        },
      }),
      invalidatesTags: [{ type: 'Students', id: 'LIST' }],
    }),
    checkPhoneSettingStudentCheckPhoneGet: build.query<
      CheckPhoneSettingStudentCheckPhoneGetApiResponse,
      CheckPhoneSettingStudentCheckPhoneGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/student/check_phone`,
        params: { token: queryArg.token, phone_num: queryArg.phoneNum },
      }),
    }),
    setStudentsListSettingStudentSetPost: build.mutation<
      SetStudentsListSettingStudentSetPostApiResponse,
      SetStudentsListSettingStudentSetPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/student/set`,
        method: "POST",
        body: queryArg.body,
        params: { token: queryArg.token },
      }),
      invalidatesTags: ['Students'],
    }),
    setQuotaSettingQuotaPost: build.mutation<
      SetQuotaSettingQuotaPostApiResponse,
      SetQuotaSettingQuotaPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/quota`,
        method: "POST",
        params: {
          token: queryArg.token,
          quota: queryArg.quota,
          trustee: queryArg.trustee,
        },
      }),
      invalidatesTags: ["Quota"],
    }),
    getMsgFormatSettingMsgFormatSubGet: build.query<
      GetMsgFormatSettingMsgFormatSubGetApiResponse,
      GetMsgFormatSettingMsgFormatSubGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/msg_format/${queryArg.sub}`,
        params: { token: queryArg.token },
      }),
    }),
    setMsgFormatSettingMsgFormatSubPost: build.mutation<
      SetMsgFormatSettingMsgFormatSubPostApiResponse,
      SetMsgFormatSettingMsgFormatSubPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/msg_format/${queryArg.sub}`,
        method: "POST",
        params: { token: queryArg.token, msg_format: queryArg.msgFormat },
      }),
      async onQueryStarted({ sub, token, msgFormat }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('getMsgFormatSettingMsgFormatSubGet', { sub, token }, (draft) => {
            draft = msgFormat
            return draft;
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getAttendanceTimesSettingAttendanceTimesGet: build.query<
      GetAttendanceTimesSettingAttendanceTimesGetApiResponse,
      GetAttendanceTimesSettingAttendanceTimesGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/attendance_times`,
        params: { token: queryArg.token },
      }),
      providesTags: [{ type: 'AttendanceTimes' }],
    }),
    setAttendanceTimesSettingAttendanceTimesPost: build.mutation<
      SetAttendanceTimesSettingAttendanceTimesPostApiResponse,
      SetAttendanceTimesSettingAttendanceTimesPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/attendance_times`,
        method: "POST",
        body: queryArg.attendanceTimes,
        params: { token: queryArg.token },
      }),
      invalidatesTags: [{ type: 'AttendanceTimes' }],
    }),
    getRegulationSettingSettingRegulationGet: build.query<
      GetRegulationSettingSettingRegulationGetApiResponse,
      GetRegulationSettingSettingRegulationGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/regulation`,
        params: { token: queryArg.token },
      }),
    }),
    setRegulationSettingSettingRegulationPost: build.mutation<
      SetRegulationSettingSettingRegulationPostApiResponse,
      SetRegulationSettingSettingRegulationPostApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/regulation`,
        method: "POST",
        body: queryArg.regulationSetting,
        params: { token: queryArg.token },
      }),
      async onQueryStarted({ token, regulationSetting }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          injectedRtkApi.util.updateQueryData('getRegulationSettingSettingRegulationGet', { token }, (draft) => {
            draft = regulationSetting
            return draft;
          })
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    massageCounterSettingMessageCounterGet: build.query<
      MassageCounterSettingMessageCounterGetApiResponse,
      MassageCounterSettingMessageCounterGetApiArg
    >({
      query: (queryArg) => ({
        url: `/setting/message-counter`,
        params: {
          token: queryArg.token,
          since: queryArg.since,
          to: queryArg.to,
        },
      }),
    }),
    getUsersListUsersGet: build.query<
      GetUsersListUsersGetApiResponse,
      GetUsersListUsersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/users`,
        params: { token: queryArg.token },
      }),
      providesTags: (result) =>
        result
          ? [
            ...result.map(({ ID }) => ({ type: 'Users' as const, id: ID })),
            { type: 'Users', id: 'LIST' },
          ]
          : [{ type: 'Users', id: 'LIST' }],
    }),
    checkUsernameUsersCheckUsernamePost: build.mutation<
      CheckUsernameUsersCheckUsernamePostApiResponse,
      CheckUsernameUsersCheckUsernamePostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/check_username`,
        method: "POST",
        params: { token: queryArg.token, username: queryArg.username },
      }),
    }),
    editUserUsersEditPost: build.mutation<
      EditUserUsersEditPostApiResponse,
      EditUserUsersEditPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/edit`,
        method: "POST",
        body: queryArg.user,
        params: { token: queryArg.token },
      }),
      invalidatesTags: [{ type: 'Users', id: 'LIST' }],
    }),
    changePasswordUsersChangepasswordPost: build.mutation<
      ChangePasswordUsersChangepasswordPostApiResponse,
      ChangePasswordUsersChangepasswordPostApiArg
    >({
      query: (queryArg) => ({
        url: `/users/changepassword`,
        method: "POST",
        params: { token: queryArg.token, new_pass: queryArg.newPass },
      }),
    }),
    getSmsExternalSmsPost: build.mutation<
      GetSmsExternalSmsPostApiResponse,
      GetSmsExternalSmsPostApiArg
    >({
      query: (queryArg) => ({
        url: `/external/sms`,
        method: "POST",
        body: queryArg.bodyGetSmsExternalSmsPost,
      }),
    }),
    goodMorningExternalGoodmorningPost: build.mutation<
      GoodMorningExternalGoodmorningPostApiResponse,
      GoodMorningExternalGoodmorningPostApiArg
    >({
      query: () => ({ url: `/external/goodmorning`, method: "POST" }),
    }),
    getCustomersExternalAdminCustomersGet: build.query<
      GetCustomersExternalAdminCustomersGetApiResponse,
      GetCustomersExternalAdminCustomersGetApiArg
    >({
      query: (queryArg) => ({
        url: `/external/admin/customers`,
        params: { token: queryArg.token },
      }),
    }),
    editCustomerExternalAdminCustomerPost: build.mutation<
      EditCustomerExternalAdminCustomerPostApiResponse,
      EditCustomerExternalAdminCustomerPostApiArg
    >({
      query: (queryArg) => ({
        url: `/external/admin/customer`,
        method: "POST",
        body: queryArg.customer,
        params: { token: queryArg.token },
      }),
    }),
    addInstLogoExternalAdminAddInstLogoPost: build.mutation<
      AddInstLogoExternalAdminAddInstLogoPostApiResponse,
      AddInstLogoExternalAdminAddInstLogoPostApiArg
    >({
      query: (queryArg) => ({
        url: `/external/admin/add_inst_logo`,
        method: "POST",
        body: queryArg.bodyAddInstLogoExternalAdminAddInstLogoPost,
        params: { token: queryArg.token, customer_id: queryArg.customerId },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as openApi };
export type PlatformGetApiResponse = /** status 200 Successful Response */ any;
export type PlatformGetApiArg = void;
export type LoginLoginPostApiResponse =
  /** status 200 Successful Response */ string;
export type LoginLoginPostApiArg = {
  login: Login;
};
export type GetPermissionGenPermissionGetApiResponse =
  /** status 200 Successful Response */ Permission;
export type GetPermissionGenPermissionGetApiArg = {
  token: string;
};
export type InstituteNameGenNameGetApiResponse =
  /** status 200 Successful Response */ string;
export type InstituteNameGenNameGetApiArg = {
  token: string;
};
export type InstituteLogoGenLogoPngGetApiResponse =
  /** status 200 Successful Response */ any;
export type InstituteLogoGenLogoPngGetApiArg = {
  token: string;
};
export type GetQuotaGenQuotaGetApiResponse =
  /** status 200 Successful Response */ number;
export type GetQuotaGenQuotaGetApiArg = {
  token: string;
  trustee?: boolean;
};
export type GroupsNamesGenGroupsGetApiResponse =
  /** status 200 Successful Response */ GroupName[];
export type GroupsNamesGenGroupsGetApiArg = {
  token: string;
};
export type CurrentAbsencesAbsenceSearchNowGetApiResponse =
  /** status 200 Successful Response */ Absence[];
export type CurrentAbsencesAbsenceSearchNowGetApiArg = {
  token: string;
};
export type ListAbsencesAbsenceSearchPeriodGetApiResponse =
  /** status 200 Successful Response */ Absence[];
export type ListAbsencesAbsenceSearchPeriodGetApiArg = {
  token: string;
  since?: string;
  to?: string;
};
export type CheckTimelineAbsenceCheckGetApiResponse =
  /** status 200 Successful Response */ Timeline;
export type CheckTimelineAbsenceCheckGetApiArg = {
  token: string;
  starting: string;
  ending: string;
  internal?: boolean;
};
export type AddAbsenceAbsenceAddPostApiResponse =
  /** status 200 Successful Response */ any;
export type AddAbsenceAbsenceAddPostApiArg = {
  token: string;
  studentId: number;
  beginning: string;
  ending: string;
};
export type DelAbsenceAbsenceDelPostApiResponse =
  /** status 200 Successful Response */ any;
export type DelAbsenceAbsenceDelPostApiArg = {
  token: string;
  absenceId: number;
  waitingList?: boolean;
};
export type GroupsListSettingGroupListGetApiResponse =
  /** status 200 Successful Response */ Group[];
export type GroupsListSettingGroupListGetApiArg = {
  token: string;
};
export type EditGroupSettingGroupEditPostApiResponse =
  /** status 200 Successful Response */ any;
export type EditGroupSettingGroupEditPostApiArg = {
  token: string;
  group: Group;
};
export type AddGroupSettingGroupAddPostApiResponse =
  /** status 200 Successful Response */ GroupName;
export type AddGroupSettingGroupAddPostApiArg = {
  token: string;
  nickname: string;
};
export type DelGroupSettingGroupDeletePostApiResponse =
  /** status 200 Successful Response */ any;
export type DelGroupSettingGroupDeletePostApiArg = {
  token: string;
  serial: number;
};
export type GetStudentsListSettingStudentsListGetApiResponse =
  /** status 200 Successful Response */ IdStudent[];
export type GetStudentsListSettingStudentsListGetApiArg = {
  token: string;
};
export type EditStudentSettingStudentEditPostApiResponse =
  /** status 200 Successful Response */ any;
export type EditStudentSettingStudentEditPostApiArg = {
  token: string;
  id: number;
  name: string;
  phoneNum: string;
  group?: number;
};
export type CheckPhoneSettingStudentCheckPhoneGetApiResponse =
  /** status 200 Successful Response */ boolean;
export type CheckPhoneSettingStudentCheckPhoneGetApiArg = {
  token: string;
  phoneNum: string;
};
export type SetStudentsListSettingStudentSetPostApiResponse =
  /** status 200 Successful Response */ any;
export type SetStudentsListSettingStudentSetPostApiArg = {
  token: string;
  body: Student[];
};
export type SetQuotaSettingQuotaPostApiResponse =
  /** status 200 Successful Response */ any;
export type SetQuotaSettingQuotaPostApiArg = {
  token: string;
  quota: number;
  trustee?: boolean;
};
export type GetMsgFormatSettingMsgFormatSubGetApiResponse =
  /** status 200 Successful Response */ string;
export type GetMsgFormatSettingMsgFormatSubGetApiArg = {
  sub: MsgTypes;
  token: string;
};
export type SetMsgFormatSettingMsgFormatSubPostApiResponse =
  /** status 200 Successful Response */ any;
export type SetMsgFormatSettingMsgFormatSubPostApiArg = {
  sub: MsgTypes;
  token: string;
  msgFormat: string;
};
export type GetAttendanceTimesSettingAttendanceTimesGetApiResponse =
  /** status 200 Successful Response */ AttendanceTimes;
export type GetAttendanceTimesSettingAttendanceTimesGetApiArg = {
  token: string;
};
export type SetAttendanceTimesSettingAttendanceTimesPostApiResponse =
  /** status 200 Successful Response */ any;
export type SetAttendanceTimesSettingAttendanceTimesPostApiArg = {
  token: string;
  attendanceTimes: AttendanceTimes;
};
export type GetRegulationSettingSettingRegulationGetApiResponse =
  /** status 200 Successful Response */ RegulationSetting;
export type GetRegulationSettingSettingRegulationGetApiArg = {
  token: string;
};
export type SetRegulationSettingSettingRegulationPostApiResponse =
  /** status 200 Successful Response */ any;
export type SetRegulationSettingSettingRegulationPostApiArg = {
  token: string;
  regulationSetting: RegulationSetting;
};
export type MassageCounterSettingMessageCounterGetApiResponse =
  /** status 200 Successful Response */ number;
export type MassageCounterSettingMessageCounterGetApiArg = {
  token: string;
  since: string;
  to: string;
};
export type GetUsersListUsersGetApiResponse =
  /** status 200 Successful Response */ User[];
export type GetUsersListUsersGetApiArg = {
  token: string;
};
export type CheckUsernameUsersCheckUsernamePostApiResponse =
  /** status 200 Successful Response */ boolean;
export type CheckUsernameUsersCheckUsernamePostApiArg = {
  token: string;
  username: string;
};
export type EditUserUsersEditPostApiResponse =
  /** status 200 Successful Response */ any;
export type EditUserUsersEditPostApiArg = {
  token: string;
  user: User;
};
export type ChangePasswordUsersChangepasswordPostApiResponse =
  /** status 200 Successful Response */ any;
export type ChangePasswordUsersChangepasswordPostApiArg = {
  token: string;
  newPass: string;
};
export type GetSmsExternalSmsPostApiResponse =
  /** status 200 Successful Response */ any;
export type GetSmsExternalSmsPostApiArg = {
  bodyGetSmsExternalSmsPost: BodyGetSmsExternalSmsPost;
};
export type GoodMorningExternalGoodmorningPostApiResponse =
  /** status 200 Successful Response */ any;
export type GoodMorningExternalGoodmorningPostApiArg = void;
export type GetCustomersExternalAdminCustomersGetApiResponse =
  /** status 200 Successful Response */ Customer[];
export type GetCustomersExternalAdminCustomersGetApiArg = {
  token: string;
};
export type EditCustomerExternalAdminCustomerPostApiResponse =
  /** status 200 Successful Response */ any;
export type EditCustomerExternalAdminCustomerPostApiArg = {
  token: string;
  customer: Customer;
};
export type AddInstLogoExternalAdminAddInstLogoPostApiResponse =
  /** status 200 Successful Response */ any;
export type AddInstLogoExternalAdminAddInstLogoPostApiArg = {
  token: string;
  customerId: number;
  bodyAddInstLogoExternalAdminAddInstLogoPost: BodyAddInstLogoExternalAdminAddInstLogoPost;
};
export type ValidationError = {
  loc: (string | number)[];
  msg: string;
  type: string;
};
export type HttpValidationError = {
  detail?: ValidationError[];
};
export type Login = {
  username: string;
  password: string;
};
export type Permission = {
  view_ab: boolean;
  edit_ab: boolean;
  setting: boolean;
  spread: boolean;
  group?: number;
};
export type GroupName = {
  serial: number;
  nickname: string;
};
export type Student = {
  name: string;
  phone_num: string;
  group?: number;
};
export type Absence = {
  ID: number;
  student: Student;
  received: string;
  beginning: string;
  ending: string;
  heb_beginning: string;
  heb_ending: string;
};
export type Timeline = {
  top_missing: number;
  start_bloked_range?: string;
  end_bloked_range?: string;
};
export type Group = {
  serial: number;
  nickname: string;
  phone: string;
  regulation?: number;
  monthly_regulation?: boolean;
  cap?: number;
  daily_report?: boolean;
  immediate_report?: boolean;
  members?: number[];
};
export type IdStudent = {
  name: string;
  phone_num: string;
  group?: number;
  ID: number;
};
export type MsgTypes =
  | "okay"
  | "reject"
  | "cencel"
  | "reminder"
  | "impermissible"
  | "illegal"
  | "pend";
export type AttendanceTimes = {
  morning: string;
  evening: string;
};
export type RegulationSetting = {
  regulation: number;
  monthly_regulation: boolean;
};
export type User = {
  ID: number;
  username: string;
  password: string;
  permission: Permission;
};
export type BodyGetSmsExternalSmsPost = {
  source: number;
  message: Blob;
};
export type Customer = {
  ID: number;
  name: string;
  attendance_licenses: number;
  main_user_username: string;
  main_user_password: string;
};
export type BodyAddInstLogoExternalAdminAddInstLogoPost = {
  logo: Blob;
};
export const {
  usePlatformGetQuery,
  useLoginLoginPostMutation,
  useGetPermissionGenPermissionGetQuery,
  useInstituteNameGenNameGetQuery,
  useInstituteLogoGenLogoPngGetQuery,
  useGetQuotaGenQuotaGetQuery,
  useGroupsNamesGenGroupsGetQuery,
  useCurrentAbsencesAbsenceSearchNowGetQuery,
  useListAbsencesAbsenceSearchPeriodGetQuery,
  useCheckTimelineAbsenceCheckGetQuery,
  useAddAbsenceAbsenceAddPostMutation,
  useDelAbsenceAbsenceDelPostMutation,
  useGroupsListSettingGroupListGetQuery,
  useEditGroupSettingGroupEditPostMutation,
  useAddGroupSettingGroupAddPostMutation,
  useDelGroupSettingGroupDeletePostMutation,
  useGetStudentsListSettingStudentsListGetQuery,
  useEditStudentSettingStudentEditPostMutation,
  useCheckPhoneSettingStudentCheckPhoneGetQuery,
  useSetStudentsListSettingStudentSetPostMutation,
  useSetQuotaSettingQuotaPostMutation,
  useGetMsgFormatSettingMsgFormatSubGetQuery,
  useSetMsgFormatSettingMsgFormatSubPostMutation,
  useGetAttendanceTimesSettingAttendanceTimesGetQuery,
  useSetAttendanceTimesSettingAttendanceTimesPostMutation,
  useGetRegulationSettingSettingRegulationGetQuery,
  useSetRegulationSettingSettingRegulationPostMutation,
  useMassageCounterSettingMessageCounterGetQuery,
  useGetUsersListUsersGetQuery,
  useCheckUsernameUsersCheckUsernamePostMutation,
  useEditUserUsersEditPostMutation,
  useChangePasswordUsersChangepasswordPostMutation,
  useGetSmsExternalSmsPostMutation,
  useGoodMorningExternalGoodmorningPostMutation,
  useGetCustomersExternalAdminCustomersGetQuery,
  useEditCustomerExternalAdminCustomerPostMutation,
  useAddInstLogoExternalAdminAddInstLogoPostMutation,
} = injectedRtkApi;
