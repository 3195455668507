import React from "react";
import { formatDistanceToNowStrict, subHours, subMinutes } from "date-fns";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Chip,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import InfoIcon from "@mui/icons-material/Info";

const now = new Date();

const activities = [
  {
    id: "5e89140bcc768199d1e0dc49",
    createdAt: subMinutes(now, 23).getTime(),
    customer: {
      id: "5e887a62195cc5aef7e8ca5d",
      avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
      name: "ביקורת בישיבת מרכז הרב!",
    },
    description: "Created account",
    type: "register",
  },
  {
    id: "5e891411b0290b175166cd322",
    createdAt: subMinutes(now, 56).getTime(),
    customer: {
      id: "5e887ac47eed253091be10cb",
      avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
      name: "ישיבת הר עציון עברו ב95 אחוז!!",
    },
    description: "Subscription Purchase",
    type: "payment",
  },
  {
    id: "5e89141633dc5e52c923ef273",
    createdAt: subHours(now, 2).getTime(),
    customer: {
      id: "5e887b209c28ac3dd97f6db5",
      avatar: "/static/mock-images/avatars/avatar-fran_perez.png",
      name: "ביקורת משרד הדתות בישיבת שדרות!",
    },
    description: "Submitted a ticket",
    type: "ticket_create",
  },
  {
    id: "5e89140bcc768199d1e0dc491",
    createdAt: subMinutes(now, 23).getTime(),
    customer: {
      id: "5e887a62195cc5aef7e8ca5d",
      avatar: "/static/mock-images/avatars/avatar-marcus_finn.png",
      name: "ביקורת בישיבת מרכז הרב!",
    },
    description: "Created account",
    type: "register",
  },
  {
    id: "5e891411b0290b175166cd342",
    createdAt: subMinutes(now, 56).getTime(),
    customer: {
      id: "5e887ac47eed253091be150cb",
      avatar: "/static/mock-images/avatars/avatar-carson_darrin.png",
      name: "ישיבת הר עציון עברו ב95 אחוז!!",
    },
    description: "Subscription Purchase",
    type: "payment",
  },
  {
    id: "5e89141633dc5e52c9243ef27",
    createdAt: subHours(now, 2).getTime(),
    customer: {
      id: "5e887b209c28ac3dd97f6db5",
      avatar: "/static/mock-images/avatars/avatar-fran_perez.png",
      name: "ביקורת משרד הדתות בישיבת שדרות!",
    },
    description: "Submitted a ticket",
    type: "ticket_create",
  },
  {
    id: "5e89141bd975c7f333aee9f4b",
    createdAt: subMinutes(now, 5).getTime(),
    customer: {
      id: "5e887b7602bdbc4dbb234b27",
      avatar: "/static/mock-images/avatars/avatar-jie_yan_song.png",
      name: "Jie Yan Song",
    },
    description: "Subscription Purchase",
    type: "payment",
  },
  {
    id: "5e891421d79457728863cf9ca",
    createdAt: subMinutes(now, 5).getTime(),
    customer: {
      id: "5e86809283e28b96d2d38537",
      avatar: "/static/mock-images/avatars/avatar-jane_rotanson.png",
      name: "Jane Rotanson",
    },
    description: "Subscription Purchase",
    type: "payment",
  },
];

const ActivityList: React.FC = () => (
  <Box
    sx={{
      backgroundColor: "background.default",
    }}
  >
    <Card
      variant="outlined"
      sx={{
        height: 520,
        width: "100%",
      }}
    >
      <CardHeader
        action={
          <IconButton>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        }
        title={
          <div>
            <div>ביקורות אחרונות</div>
            <Chip label="בקרוב" size="small" disabled sx={{ ml: 2 }} />
          </div>
        }
      />
      <Divider />
      <List
        disablePadding
        sx={{
          filter: "blur(4px)",
        }}
      >
        {activities.map((activity, i) => (
          <ListItem divider={i < activities.length - 1} key={activity.id}>
            <ListItemAvatar
              sx={{
                pl: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              <InfoIcon color="primary" />
            </ListItemAvatar>
            <ListItemText
              disableTypography
              primary={
                <Typography variant="body1">
                  {activity.customer.name}
                </Typography>
              }
              secondary={
                <Typography color="textSecondary" variant="body2">
                  {formatDistanceToNowStrict(activity.createdAt)} ago
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </Card>
  </Box>
);

export default ActivityList;
