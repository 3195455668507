import { createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { DateFormat } from 'src/app/components/Time/types';
import { AppState } from 'src/app/store'

type State = {
    dateFormat: DateFormat,
}

const initialState: State = {
    dateFormat: "HEBREW",
}

const sliceName = 'dashboardViewSlice';

const slice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        setDateFormat: (
            state,
            { payload: dateFormat }: PayloadAction<DateFormat>
        ) => {
            state.dateFormat = dateFormat;
        },
    },
})

// Selectors

export const getDateFormat = (state: AppState) => state.dashboardView.dateFormat;

export const {
    setDateFormat,
} = slice.actions

export default slice.reducer
