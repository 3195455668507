import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Box, Card, Typography, useTheme } from "@mui/material";
import { useListAbsencesGet, useQuotaGet } from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import { CURRENT_TIME } from "src/features/attendance/components/AttendanceDashboard";

const RiskMeter: React.FC = () => {
  const theme = useTheme();
  const token = useToken();
  const { isLoading: isLoadingAbsences, numOfAbsences } = useListAbsencesGet(
    {
      token,
      to: CURRENT_TIME,
    },
    {
      selectFromResult: (response) => ({
        numOfAbsences: response?.data?.length,
        ...response,
      }),
    }
  );
  const { isLoading: isLoadingQuota, data: quota } = useQuotaGet({
    token,
    trustee: true,
  });

  if (
    isLoadingAbsences ||
    isLoadingQuota ||
    numOfAbsences === undefined ||
    quota === undefined
  )
    return null;

  const risk = Math.round((numOfAbsences / quota) * 100);

  const getRiskLevel = (risk: number) => {
    if (risk < 50) return "נמוכה";
    if (risk < 75) return "בינונית";
    return "גבוהה";
  };

  const riskLevel = getRiskLevel(risk);
  const riskColor =
    risk < 75 ? theme.palette.primary.main : theme.palette.secondary.main;

  const chartOptions: ApexOptions = {
    chart: {
      type: "radialBar",
      offsetY: -20,
      sparkline: {
        enabled: true,
      },
    },
    colors: [riskColor],
    plotOptions: {
      radialBar: {
        startAngle: -90,
        endAngle: 90,
        track: {
          background: "#e7e7e7",
          strokeWidth: "97%",
          margin: 5, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: 2,
            left: 0,
            color: "#999",
            opacity: 1,
            blur: 2,
          },
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            offsetY: -2,
            fontSize: "22px",
            show: false,
          },
        },
      },
    },
    grid: {
      padding: {
        top: -10,
      },
    },
    fill: {
      // type: "gradient",
      gradient: {
        shade: "light",
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 53, 91],
      },
    },
    labels: ["Average Results"],
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: 250,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            flexWrap: "wrap",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Chart
            height="200"
            type="radialBar"
            options={chartOptions}
            series={[risk]}
          />
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography color="textSecondary" noWrap variant="body1">
              רמת סיכון עדכנית
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ fontWeight: "fontWeightBold" }}
              variant="h4"
            >
              {riskLevel}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default RiskMeter;
