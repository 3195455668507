import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import { DateFormat } from "src/app/components/Time/types";
import {
    getJewishDay,
    convertToHebrew,
    getHebJewishMonthById,
} from "jewish-dates-core";

const apiDateTimeFormat = "YYYY-MM-DDTHH:mm:ss[Z]";

// using dayjs, get the memoized time 30 days ago in ISO string format
export const get29DaysAgo = _.memoize(() => {
    return dayjs().subtract(29, 'day').startOf("day").format(apiDateTimeFormat);
});

export const get29DaysAhead = _.memoize(() => {
    return dayjs().add(29, 'day').endOf("day").format(apiDateTimeFormat);
});

export const getCurrentTime = _.memoize(() => {
    return dayjs().format(apiDateTimeFormat);
});

export const getTomorrow = _.memoize(() => {
    return dayjs().add(1, 'day').format(apiDateTimeFormat);
});

export const getStartOfToday = _.memoize(() => {
    return dayjs().startOf("day").format(apiDateTimeFormat);
});

export const getEndOfToday = _.memoize(() => {
    return dayjs().endOf("day").format(apiDateTimeFormat);
});

// using dayjs to get array of the last 30 days dates in the format of "DD/MM"
export const last30daysFormatted = (dateFormat: DateFormat) => Array.from(
    { length: 30 },
    (_, index) => {
        const currDate = dayjs()
            .subtract(29, "day")
            .add(index, "day");

        // Hebrew format
        if (dateFormat === "HEBREW") {
            const hebrewDate = getJewishDay(currDate);
            if (index === 0 || hebrewDate.jewishDate.day === 1) {
                return `${convertToHebrew(hebrewDate.jewishDate.day)}/${getHebJewishMonthById(hebrewDate.jewishDate.monthName)}`;
            }
            return convertToHebrew(hebrewDate.jewishDate.day)
        }

        // English format
        if (index === 0 || currDate.get('date') === 1) {
            return currDate.format("D/M");
        }
        return currDate.format("D");
    }
);

// using dayjs to get array of the last 30 days dates in the format of "DD/MM"
export const next30daysFormatted = (dateFormat: DateFormat) => Array.from(
    { length: 30 },
    (_, index) => {
        const currDate = dayjs()
            .add(index, "day")

        // Hebrew format
        if (dateFormat === "HEBREW") {
            const hebrewDate = getJewishDay(currDate);
            if (index === 0 || hebrewDate.jewishDate.day === 1) {
                return `${convertToHebrew(hebrewDate.jewishDate.day)}/${getHebJewishMonthById(hebrewDate.jewishDate.monthName)}`;
            }
            return convertToHebrew(hebrewDate.jewishDate.day)
        }

        if (index === 0 || currDate.get('date') === 1) {
            return currDate.format("D/M");
        }
        return currDate.format("D");
    }
);

export const getFormattedDate = (rawDate: Dayjs | Date | string, format: DateFormat) => {
    const date = dayjs(rawDate);

    switch (format) {
        case "ENGLISH":
            return date.format("DD/MM/YYYY HH:mm");
        case "HEBREW":
            return `${getJewishDay(date).jewishDateStrHebrew} ${date.format("HH:mm")}`;
    }
}

export const getFormattedDay = (rawDate: Dayjs | Date | string, format: DateFormat) => {
    const date = dayjs(rawDate);

    switch (format) {
        case "ENGLISH":
            return date.format("DD");
        case "HEBREW":
            return convertToHebrew(getJewishDay(date).jewishDate.day);
    }
}

export const getDateJewishMonth = (rawDate: Dayjs | Date | string) => {
    const date = dayjs(rawDate);
    const hebrewDate = getJewishDay(date);
    return getHebJewishMonthById(hebrewDate.jewishDate.monthName);
}

export const getDateJewishYear = (rawDate: Dayjs | Date | string) => {
    const date = dayjs(rawDate);
    const hebrewDate = getJewishDay(date);
    return convertToHebrew(hebrewDate.jewishDate.year);
}

