import React from "react";
import Chart from "react-apexcharts";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ApexOptions } from "apexcharts";
import {
  useListAbsencesGet,
  useStudentsListGet,
} from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import { CURRENT_TIME } from "src/features/attendance/components/AttendanceDashboard";
import _ from "lodash";

const AttendancePie: React.FC = () => {
  const theme = useTheme();
  const token = useToken();
  const { numOfStudents } = useStudentsListGet(
    {
      token,
    },
    {
      selectFromResult: (response) => ({
        numOfStudents: response?.data?.length,
        ...response,
      }),
    }
  );
  const { numOfAbsences } = useListAbsencesGet(
    {
      token,
      to: CURRENT_TIME,
    },
    {
      selectFromResult: (response) => ({
        numOfAbsences: response?.data?.length,
        ...response,
      }),
    }
  );

  if (_.isNil(numOfStudents) || _.isNil(numOfAbsences)) return null;

  const numOfAttendance = numOfStudents - numOfAbsences;

  const absencesPercentage = parseFloat(
    ((numOfAbsences / numOfStudents) * 100).toFixed(1)
  );
  const attendancePercentage = parseFloat(
    (100 - absencesPercentage).toFixed(1)
  );

  const data = {
    series: [
      {
        color: theme.palette.primary.main,
        percentage: attendancePercentage,
        count: numOfAttendance,
        name: "נוכחים",
      },
      {
        color: theme.palette.secondary.main,
        percentage: absencesPercentage,
        count: numOfAbsences,
        name: "נעדרים",
      },
    ],
  };

  const chartOptions: ApexOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    colors: data.series.map((item) => item.color),
    dataLabels: {
      enabled: false,
    },
    labels: data.series.map((item) => item.name),
    legend: {
      show: false,
    },
    stroke: {
      show: false,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      y: {
        formatter: (value) => `${value}%`,
      },
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: 520,
          width: "100%",
        }}
      >
        <CardHeader
          action={
            <IconButton>
              <MoreVertIcon fontSize="small" />
            </IconButton>
          }
          title="מצבת נוכחות עדכנית"
        />
        <Divider />
        <Box sx={{ p: 2 }}>
          <Chart
            height="300"
            type="donut"
            options={chartOptions}
            series={data.series.map((item) => item.percentage)}
          />
        </Box>
        <Divider />
        <Box sx={{ display: "flex" }}>
          {data.series.map((item) => (
            <Box
              key={item.name}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "center",
                px: 2,
                py: 3,
                textAlign: "center",
                "&:not(:last-of-type)": {
                  borderRight: 1,
                  borderColor: "divider",
                },
              }}
            >
              <Typography color="textPrimary" variant="h5">
                ({item.percentage.toFixed(1)}%) {item.count}
              </Typography>
              <Typography color="textSecondary" variant="overline">
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Card>
    </Box>
  );
};

export default AttendancePie;
