import React from "react";
import { Box, Grid } from "@mui/material";
import { Column } from "@material-table/core";
import { IdStudent } from "src/app/services/openApi";
import Table from "src/app/components/Table";
import { useToken } from "src/app/hooks";
import {
  useEditStudentPost,
  useGroupsNamesGet,
  useStudentsListGet,
  useStudentsListPost,
} from "src/app/services/apiHooks";
import ContentLoading from "src/app/components/ContentLoading";
import GroupSelect from "src/features/settings/components/SettingsView/StudentsSettings/GroupSelect";
import { getGroupName } from "src/features/settings/components/SettingsView/StudentsSettings/utils";
import { isValidPhoneNumber } from "src/app/utils";
import AddStudentDialog from "src/features/settings/components/SettingsView/StudentsSettings/AddStudentDialog";
import UploadStudentList from "src/features/settings/components/SettingsView/StudentsSettings/UploadStudentList";
import { isNil } from "lodash";

const StudentsSettings: React.FC = () => {
  const token = useToken();
  const { data: students } = useStudentsListGet({
    token,
  });
  const { data: groupsNames } = useGroupsNamesGet({
    token,
  });
  const [editStudent] = useEditStudentPost();
  const [setStudentsList] = useStudentsListPost();

  const columns: Column<IdStudent>[] = [
    {
      title: "שם התלמיד",
      field: "name",
      type: "string",
      width: "30%",
      validate: (student) => {
        const { name } = student;
        if (!name) return "שדה חובה";
        const nameWords = name.split(" ");
        if (nameWords.length < 2 || nameWords.length > 3)
          return "שם חייב להכיל שם פרטי ושם משפחה, ולכל היותר שם אמצעי אחד";
        for (const word of nameWords) {
          if (word.length < 2)
            return "שם פרטי או שם משפחה חייב להיות מורכב מלפחות 2 אותיות";
        }
        return true;
      },
    },
    {
      title: "מספר טלפון",
      field: "phone_num",
      type: "string",
      width: "20%",
      validate: (student) => {
        const { phone_num } = student;
        if (!phone_num) return "מספר טלפון חייב להכיל 10 ספרות";
        if (!isValidPhoneNumber(phone_num))
          return "מספר טלפון חייב להכיל מספרים בלבד";
        return true;
      },
    },
    {
      title: "קבוצה",
      field: "group",
      type: "string",
      width: "40%",
      render: (rowData) => getGroupName(rowData.group, groupsNames ?? []),
      editComponent: (props) => (
        <GroupSelect
          group={isNil(props.value) ? -1 : props.value}
          onChange={props.onChange}
        />
      ),
    },
  ];

  const handleRowUpdate = async (student: IdStudent): Promise<any> => {
    editStudent({
      token,
      id: student.ID,
      name: student.name,
      phoneNum: student.phone_num,
      group: student.group === -1 ? undefined : student.group,
    });
  };

  const handleRowDelete = async (student: IdStudent): Promise<any> => {
    if (students)
      setStudentsList({
        token,
        body: students.filter((s) => s.ID !== student.ID),
      });
  };

  if (!students) return <ContentLoading />;

  return (
    <Grid item xs={12}>
      <Table
        title="תלמידים"
        columns={columns}
        data={students}
        onRowUpdate={handleRowUpdate}
        onRowDelete={handleRowDelete}
        pageSize={20}
        toolbarComponents={{
          left: (
            <Box gap={1} display="flex">
              <UploadStudentList />
              <AddStudentDialog />
            </Box>
          ),
        }}
        toolbarActions={{
          isWithExport: true,
        }}
      />
    </Grid>
  );
};

export default StudentsSettings;
