import { Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuotaGet, useSetQuotaPost } from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";

const Quota: React.FC = () => {
  const token = useToken();
  const { data: generalQuota } = useQuotaGet({
    token,
  });
  const { data: trusteeQuota } = useQuotaGet({
    token,
    trustee: true,
  });
  const [setQuota] = useSetQuotaPost();
  const [displayGeneralQuota, setDisplayGeneralQuota] = useState(0);
  const [displayTrusteeQuota, setDisplayTrusteeQuota] = useState(0);

  useEffect(() => {
    if (generalQuota) {
      setDisplayGeneralQuota(generalQuota);
    }
  }, [generalQuota]);

  useEffect(() => {
    if (trusteeQuota) {
      setDisplayTrusteeQuota(trusteeQuota);
    }
  }, [trusteeQuota]);

  useEffect(() => {
    // TODO: implement debounce
    if (displayGeneralQuota)
      setQuota({
        token,
        quota: displayGeneralQuota,
        trustee: false,
      });
  }, [displayGeneralQuota]);

  useEffect(() => {
    // TODO: implement debounce
    if (displayTrusteeQuota)
      setQuota({
        token,
        quota: displayTrusteeQuota,
        trustee: true,
      });
  }, [displayTrusteeQuota]);

  return (
    <Grid container item spacing={2} direction="column">
      <Grid item>
        <Typography variant="body1">מספר נעדרים</Typography>
      </Grid>
      <Grid container item spacing={2}>
        {/* TODO: Add percentages */}
        <Grid item xs={6}>
          <TextField
            label="בהוספה ע״י תלמיד"
            variant="outlined"
            type="number"
            inputProps={{ min: "0" }}
            sx={{ width: "100%" }}
            value={displayGeneralQuota}
            onChange={(e) => {
              setDisplayGeneralQuota(parseInt(e.target.value));
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="בהוספה ע״י אחראי"
            variant="outlined"
            type="number"
            inputProps={{ min: "0" }}
            sx={{ width: "100%" }}
            value={displayTrusteeQuota}
            onChange={(e) => {
              setDisplayTrusteeQuota(parseInt(e.target.value));
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Quota;
