import React from "react";
import { Box, Stack, SxProps, TextField, Theme } from "@mui/material";
import { DatePicker, DateTimePickerProps, TimePicker } from "@mui/lab";
import { TimeRange } from "src/app/components/TimeRangeFilter/timeTypes";
import dayjs from "dayjs";
import { TimeRangePickerErrorProp } from "src/app/components/Time/types";
import HebDateTimePicker from "src/app/components/Time/HebDateTimePicker";

const dateTimeViews: DateTimePickerProps["views"] = ["day", "hours", "minutes"];
const dateViews: DateTimePickerProps["views"] = ["day"];
const timeViews: DateTimePickerProps["views"] = ["hours", "minutes"];

type Props = {
  timeRange: TimeRange;
  composition?: "full" | "timeOnly" | "dateOnly";
  direction?: "row" | "column";
  error?: TimeRangePickerErrorProp;
  futureOnly?: boolean;
  sx?: SxProps<Theme>;
  setTimeRange: (timeRange: TimeRange) => void;
};

const TimeRangePicker: React.FC<Props> = ({
  sx,
  timeRange,
  composition = "full",
  direction = "column",
  futureOnly,
  error,
  setTimeRange,
}) => {
  const startTime = dayjs(timeRange.startTime);
  const endTime = dayjs(timeRange.endTime);
  const now = dayjs();
  const fromMinDate = futureOnly ? now : undefined;
  const fromMinTime =
    futureOnly && startTime.startOf("day").isSame(now.startOf("day"))
      ? now
      : undefined;

  const handleDateTimeChange = (
    updateType: "date" | "time" | "datetime",
    field: "startTime" | "endTime",
    newDateTime: dayjs.Dayjs | null
  ) => {
    if (!newDateTime) return;
    const currDateTime = field === "startTime" ? startTime : endTime;

    if (updateType === "date") {
      // update only the date, not time
      newDateTime.hour(currDateTime?.hour() ?? newDateTime.hour());
      newDateTime.minute(currDateTime?.minute() ?? newDateTime.minute());
    } else if (updateType === "time") {
      // update only the time, not date
      newDateTime.year(currDateTime?.year() ?? newDateTime.year());
      newDateTime.month(currDateTime?.month() ?? newDateTime.month());
      newDateTime.date(currDateTime?.date() ?? newDateTime.date());
    }

    setTimeRange({
      ...timeRange,
      [field]: newDateTime.toDate(),
    });
  };

  const FromDate = (
    <HebDateTimePicker
      label="מתאריך"
      value={startTime}
      views={dateViews}
      error={error}
      onChange={(newDateTime) =>
        // @ts-ignore
        handleDateTimeChange("date", "startTime", newDateTime)
      }
    />
  );

  const ToDate = (
    <HebDateTimePicker
      label="לתאריך"
      value={endTime}
      views={dateViews}
      error={error}
      onChange={(newDateTime) =>
        // @ts-ignore
        handleDateTimeChange("date", "endTime", newDateTime)
      }
    />
  );

  const FromTime = (
    <HebDateTimePicker
      label="משעה"
      value={startTime}
      minDate={fromMinTime}
      views={timeViews}
      error={error}
      onChange={(newDateTime) => {
        // @ts-ignore
        handleDateTimeChange("time", "startTime", newDateTime);
      }}
    />
  );

  const ToTime = (
    <HebDateTimePicker
      label="לשעה"
      value={endTime}
      views={timeViews}
      error={error}
      onChange={(newDateTime) =>
        // @ts-ignore
        handleDateTimeChange("time", "endTime", newDateTime)
      }
    />
  );

  const fromDateTime = (
    <HebDateTimePicker
      label="מתאריך ושעה"
      value={startTime}
      minDate={fromMinDate}
      views={dateTimeViews}
      error={error}
      onChange={(newDateTime) => {
        // @ts-ignore
        handleDateTimeChange("datetime", "startTime", newDateTime);
      }}
    />
  );

  const toDateTime = (
    <HebDateTimePicker
      label="לתאריך ושעה"
      value={endTime}
      views={dateTimeViews}
      error={error}
      onChange={(newDateTime) =>
        // @ts-ignore
        handleDateTimeChange("datetime", "endTime", newDateTime)
      }
    />
  );

  const FullComposition = (
    <Box sx={{ ...sx }}>
      <Stack spacing={2} direction={direction}>
        <Box sx={{ width: "100%" }}>{fromDateTime}</Box>
        <Box sx={{ width: "100%" }}>{toDateTime}</Box>
      </Stack>
    </Box>
  );

  const TimeOnlyComposition = (
    <Box sx={{ ...sx }}>
      <Stack spacing={2} direction={direction}>
        <Box sx={{ width: "100%" }}>{FromTime}</Box>
        <Box sx={{ width: "100%" }}>{ToTime}</Box>
      </Stack>
    </Box>
  );

  const DateOnlyComposition = (
    <Box sx={{ ...sx }}>
      <Stack spacing={1} direction={direction}>
        <Box sx={{ width: "100%" }}>{FromDate}</Box>
        <Box sx={{ width: "100%" }}>{ToDate}</Box>
      </Stack>
    </Box>
  );

  switch (composition) {
    case "full":
      return FullComposition;
    case "timeOnly":
      return TimeOnlyComposition;
    case "dateOnly":
      return DateOnlyComposition;
  }
};

export default TimeRangePicker;
