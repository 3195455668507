// a function that validates that a string is a valid israeli phone number (eg. 0521234567)
export const isValidPhoneNumber = (phoneNumber: string) => {
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };
  

  export const getInstitutionName = (name?: string) => {
    if (name === "yeshiva1") return "ישיבת קיומה";
    return name ?? "שם המוסד";
  };