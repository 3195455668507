import { Card, Grid, SxProps, Theme, Typography, Tooltip, Box } from "@mui/material";
import React from "react";

type Props = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  tooltip?: string;
};

const HorizontalCard: React.FC<Props> = ({
  title,
  subtitle,
  tooltip,
  children,
  sx,
}) => {
  return (
    <Card variant="outlined" sx={{ minHeight: 200, p: 2, ...sx }}>
      <Grid container>
        <Grid item xs={4}>
          <Tooltip title={tooltip ?? ''} placement='right-start'>
            <Box>
              <Typography variant="h6">{title}</Typography>
              <Typography>{subtitle}</Typography>
            </Box>
          </Tooltip>
        </Grid>
        <Grid item container spacing={2} direction="column" xs={8}>
          {children}
        </Grid>
      </Grid>
    </Card>
  );
};

export default HorizontalCard;
