import React from "react";
import { Typography, Grid, Box, TextField, Avatar } from "@mui/material";
import HorizontalCard from "src/features/settings/components/HorizontalCard";
import { useInstituteNameGet } from "src/app/services/apiHooks";
import ContentLoading from "src/app/components/ContentLoading";
import { useToken } from "src/app/hooks";
import { getInstitutionName } from "src/app/utils";

const BasicDetails: React.FC = () => {
  const token = useToken();
  const { isLoading, data: institutionNameData } = useInstituteNameGet({
    token,
  });

  const institutionName = getInstitutionName(institutionNameData);

  return (
    <HorizontalCard title="פרטים בסיסיים">
      <Grid item>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box>
            <Avatar
              sx={{
                width: 60,
                height: 60,
                mr: 2,
              }}
            />
          </Box>
          <Box>
            <Grid item>
              <Typography variant="body1">שם המוסד</Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5">{institutionName}</Typography>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2">
          לשינוי שם או לוגו המוסד, אנא צרו קשר
        </Typography>
      </Grid>
    </HorizontalCard>
  );
};

export default BasicDetails;
