import { Localization } from "@material-table/core";

const localization: Localization = {
    header: {
        actions: 'פעולות'
    },
    body: {
        emptyDataSourceMessage: "אין נתונים להצגה",
        filterRow: {
            filterTooltip: "סינון",
        },
        editRow: {
            deleteText: ". האם אתה בטוח שברצונך למחוק את השורה?",
            cancelTooltip: "ביטול",
            saveTooltip: "שמור",
        },
    },
    toolbar: {
        searchTooltip: "חיפוש",
        searchPlaceholder: "חיפוש",
        exportTitle: "יצוא לקובץ",
    },
    pagination: {
        labelDisplayedRows: "{from}-{to} מתוך {count}",
        labelRowsSelect: "שורות",
        labelRowsPerPage: "שורות בעמוד",
        firstAriaLabel: "עמוד ראשון",
        firstTooltip: "עמוד ראשון",
        previousAriaLabel: "עמוד קודם",
        previousTooltip: "עמוד קודם",
        nextAriaLabel: "עמוד הבא",
        nextTooltip: "עמוד הבא",
        lastAriaLabel: "עמוד אחרון",
        lastTooltip: "עמוד אחרון",
    },
};

export default localization;