export type SignInRequestPayload = {
    username: string;
    password: string;
};

export type AuthUser = {
    username: string;
    permissions: string[];
}

export const AUTH_TOKEN_COOKIE = 'qma-token';
export const USERNAME_COOKIE = 'qma-username';