import { Permission } from "src/app/services/openApi";
import _ from "lodash";

const relevantPermissions = {
    view_ab: "נוכחות - צפייה",
    edit_ab: "נוכחות - עריכה",
    setting: "הגדרות",
};

export const relevantPermissionsValues = Object.values(relevantPermissions);
const relevantPermissionsKeys = Object.keys(relevantPermissions);

const permissionsDict = {
    view_ab: "נוכחות - צפייה",
    edit_ab: "נוכחות - עריכה",
    setting: "הגדרות",
    spread: "הודעות והרשמות", // TODO: add this permission
    group: "קבוצה",
};

export const getDisplayPermissions = (permissions: Permission) => {

    const permissionsArray = _.entries(permissions);

    const filteredRelevantPermissions = permissionsArray
        .filter(permission => relevantPermissionsKeys.includes(permission[0]));

    const filteredActivePermissions = filteredRelevantPermissions
        .filter(permission => permission[1]);

    const displayPermissions = filteredActivePermissions
        //   @ts-ignore
        .map(permission => permissionsDict[permission[0]]);

    return displayPermissions;
};

// opposite of getDisplayPermissions
export const getPermissionsObject = (displayPermissions: string[], permissionObj: Permission) => {
    // map displayPermissions using permissionsDict
    const truePermissions = displayPermissions.map(permission => _.invert(permissionsDict)[permission]);
    const falsePermissions = relevantPermissionsKeys.filter(permission => !truePermissions.includes(permission));

    // make any field in permissionObj that is in truePermissions to true and any field in permissionObj that is in falsePermissions to false
    const permissions = _.mapValues(permissionObj, (value, key) => {
        // ignore irrelevant permissions fields (e.g "spread", "group")
        if (!relevantPermissionsKeys.includes(key)) return value;

        if (truePermissions.includes(key)) {
            return true;
        } else if (falsePermissions.includes(key)) {
            return false;
        }
    });
    return permissions;
}