import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import GeneralSettings from "src/features/settings/components/SettingsView/GeneralSettings";
import AttendanceSettings from "src/features/settings/components/SettingsView/AttendanceSettings";
// import KitchenSettings from "src/features/settings/components/SettingsView/KitchenSettings";
import TabPanel from "src/app/components/TabPanel";
import StudentsSettings from "src/features/settings/components/SettingsView/StudentsSettings";
import { ViewBase } from "src/app/components/ViewBase";
import TabLink from "src/app/components/TabLink";
import { useTabLocation } from "src/app/hooks";

const SettingsView: React.FC = () => {
  const tabLocation = useTabLocation();

  return (
    <ViewBase>
      <Typography variant="h2" sx={{ mb: 4, mt: 2 }}>
        הגדרות
      </Typography>
      <Box sx={{ height: "100%", width: "100%", typography: "body1" }}>
        <TabContext value={tabLocation ?? ""}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList>
              <TabLink label="כללי" value="general" />
              <TabLink label="תלמידים" value="students" />
              <TabLink label="נוכחות" value="attendance" />
              {/* <TabLink label="מטבח" value="kitchen" /> */}
            </TabList>
          </Box>
          <TabPanel value="general">
            <GeneralSettings />
          </TabPanel>
          <TabPanel value="students">
            <StudentsSettings />
          </TabPanel>
          <TabPanel value="attendance">
            <AttendanceSettings />
          </TabPanel>
          {/* <TabPanel value="kitchen">
            <KitchenSettings />
          </TabPanel> */}
        </TabContext>
      </Box>
    </ViewBase>
  );
};

export default SettingsView;
