import React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import logo from "src/app/components/logo.svg";
import NavMenuLink from "src/app/components/Dashboard/NavMenuLink";
import navItems from "src/app/components/Dashboard/navItems";
import AppBar from "src/app/components/Dashboard/AppBar";
import { drawerWidth } from "src/app/components/Dashboard/constants";
import { LOGO_FILTER } from "src/app/theme";
import ScreenRotationPrompt from "src/app/components/Dashboard/ScreenRotationPrompt";
import { useAppSelector } from "src/app/hooks";
import { getAuthIsStudentsUser } from "src/features/auth/authSlice";
import Absences from "src/features/attendance/components/Absences";
import { RoutingPath } from "src/features/routing/types";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const Dashboard: React.FC<Props> = ({ window }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isStudentsUser = useAppSelector(getAuthIsStudentsUser);

  const getFilteredNavItemsList = () => {
    if (isStudentsUser) {
      return navItems.filter((item) => item.path === RoutingPath.Attendance);
    }
    return navItems;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const filteredNavItems = getFilteredNavItemsList();

  const drawer = (
    <Box sx={{ color: "text.secondary" }}>
      <Toolbar disableGutters>
        <Box sx={{ maxHeight: 64, pl: 2, pt: 1 }}>
          <Box
            component="img"
            src={logo}
            sx={{
              maxHeight: "40px",
              filter: LOGO_FILTER,
            }}
          />
        </Box>
      </Toolbar>
      <List sx={{ mt: 1 }}>
        {filteredNavItems.map((navItem) => (
          <NavMenuLink key={navItem.label} navItem={navItem} />
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <ScreenRotationPrompt />
      <Box sx={{ height: "100%", display: { xs: "none", sm: "unset" } }}>
        <CssBaseline />
        <AppBar
          isMobileNavMenuOpen={mobileOpen}
          setIsMobileNavMenuOpen={setMobileOpen}
        />
        <Box
          component="nav"
          sx={{
            width: { md: drawerWidth },
            flexShrink: { md: 0 },
          }}
        >
          {/* Mobile */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "none", sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>

          {/* Desktop */}
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", md: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                background: "none",
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            height: "100%",
            width: { md: `calc(100% - ${drawerWidth})` },
            transition: (theme) =>
              theme.transitions.create("margin", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            marginLeft: { md: `${drawerWidth}` },
            ...(mobileOpen && {
              transition: (theme) =>
                theme.transitions.create("margin", {
                  easing: theme.transitions.easing.easeOut,
                  duration: theme.transitions.duration.enteringScreen,
                }),
              marginRight: 0,
            }),
          }}
        >
          <Toolbar />
          <Box
            sx={{
              height: "100%",
            }}
          >
            {isStudentsUser ? <Absences /> : <Outlet />}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Dashboard;
