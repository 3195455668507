import React from "react";
import { format, subDays } from "date-fns";
import {
  Avatar,
  Box,
  Card,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import { useMessageCounterGet } from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import {
  CURRENT_TIME,
  TWENTY_NINE_DAYS_AGO,
} from "src/features/attendance/components/AttendanceDashboard";
import _ from "lodash";

const getCategories = () => {
  const now = new Date();
  const categories = [];

  for (let i = 6; i >= 0; i--) {
    categories.push(format(subDays(now, i), "dd/MM/yyyy"));
  }

  return categories;
};

const MessagesCount: React.FC = () => {
  const theme = useTheme();
  const token = useToken();
  const { isLoading, data: messageCount } = useMessageCounterGet({
    token,
    since: TWENTY_NINE_DAYS_AGO,
    to: CURRENT_TIME,
  });

  if (_.isNil(messageCount)) return null;

  const chart = {
    options: {
      chart: {
        background: "transparent",
        toolbar: {
          show: false,
        },
        zoom: false,
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        yaxis: {
          lines: {
            show: false,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      legend: {
        show: false,
      },
      stroke: {
        width: 2,
        colors: "#f44336",
      },
      theme: {
        mode: theme.palette.mode,
      },
      tooltip: {
        theme: theme.palette.mode,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        categories: getCategories(),
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
    },
    series: [
      {
        data: [14, 43, 98, 68, 155, 18, 8],
        name: "Conversions",
      },
    ],
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: 250,
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            flexWrap: "wrap",
            height: "100%",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Avatar
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              width: 70,
              height: 70,
            }}
          >
            <MessageIcon fontSize="large" />
          </Avatar>
          <Box
            sx={{
              mt: 3,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography color="textSecondary" noWrap variant="body1">
              הודעות שנשלחו בחודש האחרון
            </Typography>
            <Typography
              color="textPrimary"
              sx={{ fontWeight: "fontWeightBold" }}
              variant="h4"
            >
              {messageCount}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default MessagesCount;
