import React, { useState } from "react";
import {
  Collapse,
  ListItemText,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  TimeFilter,
  TimeFilterId,
  TimeRange,
} from "src/app/components/TimeRangeFilter/timeTypes";
import TimeRangePicker from "src/app/components/TimeRangePicker";

type Props = {
  currentFilter: TimeFilter;
  setFilter: (timeFilter: TimeFilter) => void;
};

const CustomTimeFilter: React.FC<Props> = ({ currentFilter, setFilter }) => {
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const customTimeFilterLabel = "מותאם אישית";

  const handleTimeRangeChange = (timeRange: TimeRange) => {
    setFilter({
      id: TimeFilterId.Custom,
      label: customTimeFilterLabel,
      timeRange,
    });
  };

  return (
    <div>
      <MenuItem
        onClick={() => setIsCollapseOpen(!isCollapseOpen)}
        selected={currentFilter.id === TimeFilterId.Custom}
      >
        <ListItemText primary={customTimeFilterLabel} />
        <ListItemIcon>
          {isCollapseOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemIcon>
      </MenuItem>
      <Collapse in={isCollapseOpen} timeout="auto">
        <MenuItem>
          <TimeRangePicker
            timeRange={currentFilter.timeRange}
            setTimeRange={handleTimeRangeChange}
            composition="dateOnly"
          />
        </MenuItem>
      </Collapse>
    </div>
  );
};

export default CustomTimeFilter;
