import { createSelector, createSlice, PayloadAction, } from '@reduxjs/toolkit'
import { AppState } from 'src/app/store'

type AuthState = {
  username: string | null,
  token: string,
}

const initialState: AuthState = {
  username: null,
  token: '',
}

const sliceName = 'auth';

const slice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { username, token } }: PayloadAction<{ username: string; token: string }>
    ) => {
      state.username = username
      state.token = token
    },
    authSignOut: () => initialState,
  },
})

// Selectors

export const getAuthUsername = (state: AppState) => state.auth.username;

export const getAuthIsStudentsUser = createSelector(
  getAuthUsername,
  (username) => username ? username.includes('student') : true
);

export const getAuthToken = (state: AppState) => state.auth.token;

export const getAuthIsUserSignedIn = createSelector(
  [getAuthUsername, getAuthToken],
  (username, token) => !!username && !!token
);

export const {
  setCredentials,
  authSignOut,
} = slice.actions

export default slice.reducer

export const selectCurrentUser = (state: AppState) => state.auth.username
