import { Grid, Typography } from "@mui/material";
import React from "react";
import TimeRangePicker from "src/app/components/TimeRangePicker";
import {
  useAttendanceTimesGet,
  useAttendanceTimesPost,
} from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import dayjs from "dayjs";

const AttendanceTimes: React.FC = () => {
  const token = useToken();
  const { isLoading: isLoadingAttendanceTimes, data: attendanceTimes } =
    useAttendanceTimesGet({
      token,
    });
  const [setAttendanceTimes] = useAttendanceTimesPost();

  const formattedAttendanceTimes = {
    startTime: attendanceTimes?.morning
      ? new Date(`01/01/2020 ${attendanceTimes.morning}`)
      : new Date(),
    endTime: attendanceTimes?.evening
      ? new Date(`01/01/2020 ${attendanceTimes.evening}`)
      : new Date(),
  };

  return (
    <Grid container item spacing={2} direction="column">
      <Grid item>
        <Typography variant="body1">שעות דיווח</Typography>
      </Grid>
      <Grid item>
        <TimeRangePicker
          composition="timeOnly"
          direction="row"
          timeRange={{
            startTime: formattedAttendanceTimes.startTime,
            endTime: formattedAttendanceTimes.endTime,
          }}
          setTimeRange={(newTimeRange) => {
            if (newTimeRange.startTime && newTimeRange.endTime)
              setAttendanceTimes({
                token,
                attendanceTimes: {
                  morning: dayjs(newTimeRange.startTime).format("HH:mm:ss"),
                  evening: dayjs(newTimeRange.endTime).format("HH:mm:ss"),
                },
              });
          }}
        />
      </Grid>
    </Grid>
  );
};

export default AttendanceTimes;
