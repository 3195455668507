import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import TimeRangePicker from "src/app/components/TimeRangePicker";
import {
  useStudentsListGet,
  useAddAbsencePost,
  useCheckTimelineGet,
} from "src/app/services/apiHooks";
import ContentLoading from "src/app/components/ContentLoading";
import { IdStudent } from "src/app/services/openApi";
import * as yup from "yup";
import { useFormik } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import dayjs from "dayjs";
import { useToken } from "src/app/hooks";

const validationSchema = yup.object({
  student: yup.object({
    name: yup.string().required("שדה חובה").min(2, "חייב להיות לפחות 2 תווים"),
    phone_num: yup
      .string()
      .required("שדה חובה")
      .min(10, "מספר טלפון לא תקין")
      .max(10, "מספר טלפון לא תקין"),
    group: yup.number().nullable(),
    ID: yup.number().required("שדה חובה").moreThan(-1, "חובה לבחור מס׳ ייחודי"),
  }),
  timeRange: yup.object({
    startTime: yup
      .date()
      .nullable()
      .min(new Date(), "זמן ההתחלה לא יכול להקדים את הזמן הנוכחי")
      .required("שדה חובה"),
    endTime: yup
      .date()
      .nullable()
      .min(new Date(), "זמן הסיום לא יכול להקדים את הזמן הנוכחי")
      .required("שדה חובה"),
  }),
});

type AvailabilityCheckState = {
  requestStatus: "UNCHECKED" | "CHECKED";
  requestData: {
    startTime: string;
    endTime: string;
  };
};

const availabilityCheckInitialState: AvailabilityCheckState = {
  requestStatus: "UNCHECKED",
  requestData: {
    startTime: "",
    endTime: "",
  },
};

const AddAbsenceDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [availabilityCheck, setAvailabilityCheck] =
    useState<AvailabilityCheckState>(availabilityCheckInitialState);
  const token = useToken();
  const { data: students, isFetching: isStudentsLoading } = useStudentsListGet({
    token,
  });
  const { data: availability, isFetching: isAvailabilityLoading } =
    useCheckTimelineGet(
      {
        token,
        starting: availabilityCheck.requestData.startTime,
        ending: availabilityCheck.requestData.endTime,
      },
      {
        skip:
          !availabilityCheck.requestData.startTime ||
          !availabilityCheck.requestData.endTime,
      }
    );
  const [addAbsence, { isLoading: isAdding }] = useAddAbsencePost();

  const formik = useFormik({
    initialValues: {
      student: {
        name: "",
        phone_num: "",
        group: -1,
        ID: -1,
      },
      timeRange: {
        startTime: undefined,
        endTime: undefined,
      },
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await addAbsence({
          token,
          studentId: values.student.ID ?? 0,
          beginning: dayjs(values.timeRange.startTime).format() ?? "",
          ending: dayjs(values.timeRange.endTime).format() ?? "",
        });
        setIsOpen(false);
        setAvailabilityCheck(availabilityCheckInitialState);
        formik.resetForm();
      } catch (error) {
        console.error("rejected", error);
      }
    },
  });

  const isDialogLoading = isStudentsLoading || isAdding || !students;

  const timeRangeFromError = {
    state:
      formik.touched.timeRange?.startTime &&
      Boolean(formik.errors.timeRange?.startTime),
    message:
      formik.touched.timeRange?.startTime && formik.errors.timeRange?.startTime,
  };
  const timeRangeToError = {
    state:
      formik.touched.timeRange?.endTime &&
      Boolean(formik.errors.timeRange?.endTime),
    message:
      formik.touched.timeRange?.endTime && formik.errors.timeRange?.endTime,
  };

  const getAvailabilityResponseContent = () => {
    if (!availability) return null;
    const isAvailable = !(
      availability?.start_bloked_range && availability?.end_bloked_range
    );
    if (isAvailable)
      return <Typography variant="caption">הזמן המבוקש זמין.</Typography>;
    const blockedEndTime = dayjs(availability.start_bloked_range).format(
      "HH:mm DD/MM/YYYY"
    );
    const blockedStartTime = dayjs(availability.end_bloked_range).format(
      "HH:mm DD/MM/YYYY"
    );
    const { top_missing } = availability;
    return (
      <Typography variant="caption" color="error" sx={{ mt: 2 }}>
        {`שימו לב! בין ${blockedEndTime} לבין ${blockedStartTime} נרשמו כבר ${top_missing} תלמידים אשר מלאו את המכסה. ניתן להוסיף את ההעדרות בכל זאת, אך בכך תעברו את המכסה!`}
      </Typography>
    );
  };

  const getCheckAvailabilityButtonContent = () => {
    if (isAvailabilityLoading) {
      return <CircularProgress size={20} sx={{ color: "#fff" }} />;
    }
    return "בדיקת זמינות";
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
    formik.resetForm();
    setAvailabilityCheck(availabilityCheckInitialState);
  };

  const handleAvailabilityCheck = async () => {
    await formik.setTouched({
      student: {
        name: true,
        phone_num: true,
        group: true,
        ID: true,
      },
      timeRange: {
        startTime: true,
        endTime: true,
      },
    });
    await formik.validateForm().then((errors) => {
      const isValid = !Object.keys(errors).length;
      if (isValid) {
        setAvailabilityCheck({
          ...availabilityCheck,
          requestStatus: "CHECKED",
          requestData: {
            startTime: dayjs(formik.values.timeRange.startTime).format() ?? "",
            endTime: dayjs(formik.values.timeRange.endTime).format() ?? "",
          },
        });
      }
    });
  };

  const Loading = (
    <DialogContent
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <ContentLoading />
    </DialogContent>
  );
  const Form = (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <DialogContent>
        <Typography>יש לבחור תלמיד, ואת הזמן המבוקש להעדרות.</Typography>
        <Typography>
          לאחר מכן, יש לבדוק זמינות כדי לוודא שההעדרות אינה עולה על מכסת
          התלמידים בזמן הנתון.
        </Typography>
        <Autocomplete<IdStudent>
          id="student-select"
          options={students ?? []} // IdStudent[]
          value={formik.values.student}
          autoSelect
          onChange={(event, newValue) => {
            formik.setFieldValue("student", newValue);
          }}
          getOptionLabel={(option) => option.name}
          filterOptions={(options, state) => {
            return options.filter(
              (option) =>
                option.name.includes(state.inputValue) ||
                option.phone_num.includes(state.inputValue)
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="תלמיד"
              error={
                formik.touched.student?.name &&
                Boolean(formik.errors.student?.name)
              }
              helperText={
                formik.touched.student?.name && formik.errors.student?.name
              }
              inputProps={{
                ...params.inputProps,
              }}
            />
          )}
          renderOption={(props, option) => (
            <ListItem {...props}>
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar />
                </ListItemAvatar>
                <ListItemText
                  primary={option.name}
                  secondary={option.phone_num}
                />
              </ListItemButton>
            </ListItem>
          )}
          sx={{
            width: "100%",
            mt: 2,
          }}
        />
        <TimeRangePicker
          futureOnly
          timeRange={formik.values.timeRange}
          setTimeRange={(newTimeRange) => {
            formik.setFieldValue("timeRange", newTimeRange);
          }}
          sx={{ mt: 2 }}
          error={{
            from: {
              date: timeRangeFromError,
              time: timeRangeFromError,
            },
            to: {
              date: timeRangeToError,
              time: timeRangeToError,
            },
          }}
        />
        {getAvailabilityResponseContent()}
      </DialogContent>
      <DialogActions
        sx={{
          pr: 2,
          pb: 2,
        }}
      >
        <Button onClick={handleCancel}>ביטול</Button>
        <Button
          variant="contained"
          onClick={() => {
            if (!isAvailabilityLoading) handleAvailabilityCheck();
          }}
          sx={{
            width: "110px",
            height: "36.5px",
          }}
        >
          {getCheckAvailabilityButtonContent()}
        </Button>
        <Button
          variant="contained"
          disabled={
            availabilityCheck.requestStatus !== "CHECKED" ||
            isAvailabilityLoading
          }
          onClick={() => {
            formik.submitForm();
          }}
        >
          הוספה
        </Button>
      </DialogActions>
    </Box>
  );

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<AddCircleOutlineIcon />}
      >
        הוספה
      </Button>
      {isOpen && (
        <Dialog open={isOpen}>
          <DialogTitle>הוספת העדרות חדשה</DialogTitle>
          <Box
            sx={{
              width: "400px",
              height: "fit-content",
            }}
          >
            {isDialogLoading ? Loading : Form}
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default AddAbsenceDialog;
