import { DateTimePicker, DateTimePickerProps, PickersDay } from "@mui/lab";
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { getDateFormat } from "src/app/components/Dashboard/dashboardViewSlice";
import { useAppSelector, useIsDesktop } from "src/app/hooks";
import {
  getDateJewishMonth,
  getDateJewishYear,
  getFormattedDay,
} from "src/app/utils/timeUtils";
import { unset, isEqual } from "lodash";
import dayjs from "dayjs";
import { getJewishDay } from "jewish-dates-core";
import {
  DateFormat,
  TimeRangePickerErrorProp,
} from "src/app/components/Time/types";

type Props = {
  error?: TimeRangePickerErrorProp;
  views: DateTimePickerProps["views"];
} & Omit<DateTimePickerProps, "renderInput" | "views">;

const HebDateTimePicker: React.FC<Props> = ({ views, error, ...props }) => {
  const [currDate, setCurrDate] = useState(props.value);
  const [isOpen, setIsOpen] = useState(false);
  const dateFormat = useAppSelector(getDateFormat);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (dateFormat === "HEBREW")
      setTimeout(() => {
        const month = document
          .getElementsByClassName("MuiCalendarPicker-root")[0]
          .getElementsByTagName("div")[3];
        if (month) {
          // @ts-ignore
          const currHebMonth = getDateJewishMonth(currDate);
          // @ts-ignore
          const nextHebMonth = getDateJewishMonth(currDate.add(1, "month"));
          month.innerHTML = `${currHebMonth} - ${nextHebMonth}`;
        }

        const year = document
          .getElementsByClassName("MuiCalendarPicker-root")[0]
          .getElementsByTagName("div")[4]
          .getElementsByTagName("div")[0];
        if (year) {
          // @ts-ignore
          const currHebYear = getDateJewishYear(currDate);
          year.innerHTML = `${currHebYear}`;
        }
      }, 50);
  }, [isOpen, currDate, dateFormat]);

  return (
    <DateTimePicker
      {...props}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      open={isOpen}
      views={views}
      ampm={false}
      renderDay={(day, selectedDate, pickersDayProps) => {
        return (
          <PickersDay
            day={pickersDayProps.day}
            onDaySelect={pickersDayProps.onDaySelect}
            outsideCurrentMonth={pickersDayProps.outsideCurrentMonth}
            selected={pickersDayProps.selected}
            disabled={pickersDayProps.disabled}
            allowSameDateSelection={true}
          >
            {/* @ts-ignore **/}
            {getFormattedDay(day, dateFormat)}
          </PickersDay>
        );
      }}
      onMonthChange={(newDateTime) => {
        // @ts-ignore
        setCurrDate(newDateTime);
      }}
      renderInput={(params) => {
        const inputValue = getInputValue(
          params.inputProps?.value,
          dateFormat,
          views
        );

        unset(params, "inputProps.value");

        if (params.inputProps) params.inputProps.disabled = isDesktop;

        return (
          <TextField
            {...params}
            value={inputValue}
            sx={{ width: "100%" }}
            error={error?.from?.date?.state}
            helperText={error?.from?.date?.message}
            disabled={isDesktop}
          />
        );
      }}
    />
  );
};

export default HebDateTimePicker;

const getInputValue = (
  dateTimeValue: string,
  dateFormat: DateFormat,
  views: DateTimePickerProps["views"]
) => {
  const dateTime = dateTimeValue;
  const currDateTime = dayjs(dateTime, "DD/MM/YYYY HH:mm");

  const date = currDateTime.format("DD/MM/YYYY");
  const hebrewDate = getJewishDay(currDateTime).jewishDateStrHebrew;
  const time = currDateTime.format("HH:mm");
  const hebrewDateTime = `${hebrewDate} ${time}`;

  if (dateFormat === "HEBREW") {
    if (isEqual(views, ["day"])) return hebrewDate;
    if (isEqual(views, ["day", "hours", "minutes"])) return hebrewDateTime;
  }
  if (isEqual(views, ["day"])) return date;
  if (isEqual(views, ["day", "hours", "minutes"])) return dateTime;
  return time;
};
