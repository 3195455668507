import React from "react";
import Table from "src/app/components/Table";
import { Column } from "@material-table/core";
import { Group } from "src/app/services/openApi";
import {
  useGroupsListGet,
  useEditGroupPost,
  useStudentsListGet,
  useAddGroupPost,
  useDelGroupPost,
} from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import { Button, Switch, ToggleButton, ToggleButtonGroup } from "@mui/material";
import TableIconButton from "src/app/components/Table/TableIconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { isValidPhoneNumber } from "src/app/utils";

const GroupsLimitations: React.FC = () => {
  const token = useToken();
  const { data: groups } = useGroupsListGet({
    token,
  });
  const [editGroup] = useEditGroupPost();
  const [addGroup] = useAddGroupPost();
  const [deleteGroup] = useDelGroupPost();
  const { data: students } = useStudentsListGet({
    token,
  });

  if (!students || !groups) return null; // TODO: show loading

  const handleRowUpdate = async (group: Group): Promise<any> => {
    const { serial: groupId } = group;
    const groupMembersIds = students
      .filter((student) => student.group === groupId)
      .map((student) => student.ID);

    editGroup({
      token,
      group: {
        ...group,
        members: groupMembersIds,
      },
    });
  };

  const handleRowAdd = () => {
    addGroup({
      token,
      nickname: "קבוצה חדשה",
    });
  };

  const handleRowDelete = async (group: Group): Promise<any> => {
    deleteGroup({
      token,
      serial: group.serial,
    });
  };

  return (
    <Table
      title="מגבלות קבוצתיות"
      columns={columns}
      data={groups ?? []}
      onRowUpdate={handleRowUpdate}
      onRowDelete={handleRowDelete}
      toolbarComponents={{
        left: (
          <Button
            variant="contained"
            onClick={handleRowAdd}
            startIcon={<AddCircleOutlineIcon />}
          >
            הוספה
          </Button>
        ),
      }}
      toolbarActions={{
        isWithExport: true,
      }}
    />
  );
};

export default GroupsLimitations;

const columns: Column<Group>[] = [
  {
    title: "מס׳ הקבוצה",
    field: "serial",
    type: "numeric",
    width: 70,
    align: "center",
    editable: "never",
  },
  { title: "אחראי הקבוצה", field: "nickname", type: "string" },
  {
    title: "מספר טלפון",
    field: "phone",
    type: "string",
    width: 150,
    validate: (rowData) => {
      const { phone } = rowData;
      if (!phone) return "מספר טלפון חובה";
      if (!isValidPhoneNumber(phone)) return "מספר טלפון לא תקין";
      return true;
    },
  },
  {
    title: "מגבלת שעות",
    field: "regulation",
    type: "numeric",
    render: (rowData) => {
      if (rowData.regulation === null) {
        return "ללא מגבלה";
      }
      return rowData.regulation + " שעות";
    },
    editPlaceholder: "השאירו ריק כדי לא להגביל",
  },
  {
    title: "טווח מגבלת שעות",
    field: "monthly_regulation",
    type: "boolean",
    render: (rowData) => (
      <ToggleButtonGroup
        disabled
        color="primary"
        value={rowData.monthly_regulation}
        exclusive
        sx={{
          height: "100%",
        }}
      >
        <ToggleButton value={false}>שבועי</ToggleButton>
        <ToggleButton value={true}>חודשי</ToggleButton>
      </ToggleButtonGroup>
    ),
    editComponent: (props) => (
      <ToggleButtonGroup
        color="primary"
        value={props.value}
        exclusive
        onChange={(event, value) => {
          props.onChange(value);
        }}
        sx={{
          height: "100%",
        }}
      >
        <ToggleButton value={false}>שבועי</ToggleButton>
        <ToggleButton value={true}>חודשי</ToggleButton>
      </ToggleButtonGroup>
    ),
  },
  {
    title: "מגבלת נעדרים",
    field: "cap",
    type: "numeric",
    render: (rowData) => {
      if (rowData.cap === null) {
        return "ללא מגבלה";
      }
      return rowData.cap + " נעדרים";
    },
    editPlaceholder: "השאירו ריק כדי לא להגביל",
  },
  {
    title: "דיווח מיידי",
    field: "immediate_report",
    type: "boolean",
    render: (rowData) => <Switch checked={rowData.immediate_report} />,
    editComponent: (props) => (
      <Switch
        checked={props.value}
        onChange={(event, value) => {
          props.onChange(value);
        }}
      />
    ),
  },
  {
    title: "דיווח יומי",
    field: "daily_report",
    type: "boolean",
    render: (rowData) => <Switch checked={rowData.daily_report} />,
    editComponent: (props) => (
      <Switch
        checked={props.value}
        onChange={(event, value) => {
          props.onChange(value);
        }}
      />
    ),
  },
];
