import FactCheckIcon from "@mui/icons-material/FactCheck";
// import HomeIcon from "@mui/icons-material/Home";
// import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
// import RestaurantIcon from "@mui/icons-material/Restaurant";
import { NavItem } from "src/app/components/Dashboard/types";
import { RoutingPath } from "src/features/routing/types";
import SettingsIcon from "@mui/icons-material/Settings";

const navItems: NavItem[] = [
  // {
  //   label: "עמוד הבית",
  //   icon: <HomeIcon />,
  //   path: RoutingPath.Home,
  //   isBeta: true,
  // },
  {
    label: "נוכחות",
    icon: <FactCheckIcon />,
    path: RoutingPath.Attendance,
  },
  // {
  //   label: "מטבח",
  //   icon: <RestaurantIcon />,
  //   path: RoutingPath.Kitchen,
  //   isBeta: true,
  // },
  // {
  //   label: "מועמדים",
  //   icon: <AssignmentIndIcon />,
  //   path: RoutingPath.Candidates,
  //   isBeta: true,
  // },
  {
    label: "הגדרות",
    icon: <SettingsIcon />,
    path: RoutingPath.Settings,
  },
];

export default navItems;
