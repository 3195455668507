import React from "react";
import { Tab } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

type Props = {
  label: string;
  value: string;
};

const TabLink: React.FC<Props> = ({ label, value }) => {
  const { pathname } = useLocation();
  const rootLocation = pathname.split("/")[1];

  return (
    <Tab
      label={label}
      value={value}
      component={Link}
      to={`/${rootLocation}/${value}`}
    />
  );
};

export default TabLink;
