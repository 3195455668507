import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'src/features/auth/authSlice';
import dashboardViewReducer from 'src/app/components/Dashboard/dashboardViewSlice';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { openApi } from 'src/app/services/openApi';

const epicMiddleware = createEpicMiddleware();

const rooEpic = combineEpics();
epicMiddleware.run(rooEpic);

export const store = configureStore({
  reducer: {
    [openApi.reducerPath]: openApi.reducer,
    auth: authReducer,
    dashboardView: dashboardViewReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(openApi.middleware)
      .concat(epicMiddleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
