import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const UploadStudentList = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContact = () => {
    // @ts-ignore
    window?.open("https://qma.co.il/contact/", "_blank").focus();
    handleClose();
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        העלאת רשימה חדשה
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>העלאת רשימה חדשה </DialogTitle>
        <DialogContent>
          <DialogContentText>
            להעלאת רשימת תלמידים שלמה אשר תחליף את הרשימה הנוכחית יש ליצור קשר.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>סגירה</Button>
          <Button onClick={handleContact} variant="contained">
            צור קשר
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UploadStudentList;
