import React from "react";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTheme, Box, Card, CardContent, CardHeader } from "@mui/material";
import { last30daysFormatted } from "src/app/utils/timeUtils";
import {
  useQuotaGet,
  useStudentsListGet,
  useListAbsencesGet,
} from "src/app/services/apiHooks";
import { useAppSelector, useToken } from "src/app/hooks";
import _ from "lodash";
import {
  END_OF_TODAY,
  TOMORROW,
  TWENTY_NINE_DAYS_AGO,
} from "src/features/attendance/components/AttendanceDashboard";
import { absencesPerDay } from "src/features/attendance/components/AttendanceDashboard/utils";
import { getDateFormat } from "src/app/components/Dashboard/dashboardViewSlice";

const AttendanceOverTime: React.FC = () => {
  const theme = useTheme();
  const token = useToken();
  const dateFormat = useAppSelector(getDateFormat);
  const LAST_30_DAYS_FORMATTED = last30daysFormatted(dateFormat);
  const { data: quota } = useQuotaGet({
    token,
    trustee: true,
  });
  const { numOfStudents } = useStudentsListGet(
    {
      token,
    },
    {
      selectFromResult: (response) => ({
        numOfStudents: response?.data?.length,
        ...response,
      }),
    }
  );
  const { numOfAbsencesPerDay } = useListAbsencesGet(
    {
      token,
      since: TWENTY_NINE_DAYS_AGO,
      to: END_OF_TODAY,
    },
    {
      selectFromResult: (response) => ({
        numOfAbsencesPerDay: response?.data
          ? absencesPerDay(
              response?.data,
            )
          : undefined,
        ...response,
      }),
    }
  );

  if (_.isNil(numOfStudents) || _.isNil(quota) || _.isNil(numOfAbsencesPerDay))
    return null;

  const quotaPercentage = 100 - Math.floor((quota / numOfStudents) * 100);
  const LAST_30_DAYS_QUOTA = Array.from({ length: 30 }, (_) => quotaPercentage);
  const LAST_30_DAYS_ABSENCES = numOfAbsencesPerDay.map(
    (numOfAbsences) => 100 - Math.floor((numOfAbsences / numOfStudents) * 100)
  );

  const chartOptions: ApexOptions = {
    chart: {
      background: "transparent",
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [theme.palette.primary.main, theme.palette.secondary.main],
    dataLabels: {
      enabled: false,
    },
    grid: {
      borderColor: theme.palette.divider,
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    legend: {
      horizontalAlign: "right",
      labels: {
        colors: theme.palette.text.secondary,
      },
      position: "top",
      show: true,
    },
    markers: {
      hover: {
        size: undefined,
        sizeOffset: 2,
      },
      radius: 2,
      shape: "circle",
      size: 4,
      strokeColors: ["#1f87e6", "#27c6db"],
      strokeWidth: 0,
    },
    stroke: {
      curve: "smooth",
      dashArray: [0, 3],
      lineCap: "butt",
      width: 3,
    },
    theme: {
      mode: theme.palette.mode,
    },
    tooltip: {
      theme: theme.palette.mode,
      y: {
        formatter: (value) => `${value}%`,
      },
    },
    xaxis: {
      axisBorder: {
        color: theme.palette.divider,
      },
      axisTicks: {
        color: theme.palette.divider,
        show: true,
      },
      categories: LAST_30_DAYS_FORMATTED,
      labels: {
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: [
      {
        min: 70,
        max: 100,
        axisBorder: {
          color: theme.palette.divider,
          show: true,
        },
        axisTicks: {
          color: theme.palette.divider,
          show: true,
        },
        labels: {
          style: {
            colors: theme.palette.text.secondary,
          },
        },
      },
    ],
  };

  const series = [
    {
      data: LAST_30_DAYS_ABSENCES,
      name: "אחוזי נוכחות בפועל",
    },
    {
      data: LAST_30_DAYS_QUOTA,
      name: "אחוזי נוכחות דרושים",
    },
  ];
  return (
    <Box
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: "100%",
        }}
      >
        <CardHeader title="ביצועים ב-30 הימים האחרונים" />
        <CardContent>
          <Chart
            height="300"
            type="line"
            options={chartOptions}
            series={series}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AttendanceOverTime;
