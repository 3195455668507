import React from "react";
import { LOGO_FILTER } from "src/app/theme";
import { Typography } from "@mui/material";
import ScreenRotationIcon from "@mui/icons-material/ScreenRotation";
import Box from "@mui/material/Box";
import logo from "src/app/components/logo.svg";

const ScreenRotationPrompt = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: { xs: "flex", sm: "none" },
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 2,
            flexGrow: 1,
          }}
        >
          <ScreenRotationIcon fontSize="large" />
          <Typography variant="h6">
            לגלישה בנייד, אנא סובבו את המסך לרוחב
          </Typography>
        </Box>
        <Box
          component="img"
          src={logo}
          sx={{
            maxHeight: 48,
            filter: LOGO_FILTER,
            mb: 2,
          }}
        />
      </Box>
    </Box>
  );
};

export default ScreenRotationPrompt;
