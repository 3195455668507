import React, { useState } from "react";
import ChatIcon from "@mui/icons-material/Chat";
import { useAppSelector, useIsDesktop } from "src/app/hooks";
import { addFeedback } from "src/features/feedback/ports";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Fab, Box, CardHeader, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import * as yup from "yup";
import { useFormik } from "formik";
import { getAuthUsername } from "src/features/auth/authSlice";

const validationSchema = yup.object({
  description: yup.string().required("יש להזין תיאור"),
});

type Stage = "DRAFT" | "SENDING" | "SENT" | "ERROR";

const FeedbackWidget: React.FC = () => {
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>("DRAFT");
  const userId = useAppSelector(getAuthUsername);
  const formik = useFormik({
    initialValues: {
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (feedback, { resetForm }) => {
      const reset = () => {
        setTimeout(() => {
          setIsOpen(false);
          setStage("DRAFT");
          resetForm();
        }, 3000);
      };
      
      try {
        setStage("SENDING");
        await addFeedback({
          description: feedback.description,
          userId,
        });
        setStage("SENT");
        reset();
      } catch (error) {
        console.error(error);
        setStage("ERROR");
        reset();
      }
    },
  });

  // TODO: Support on mobile
  if (!isDesktop) return null;

  const form = (
    <div>
      <CardContent
        sx={{ height: "100%", display: "flex", flexDirection: "column" }}
      >
        <Typography sx={{ mt: 1, mb: 2 }}>
          הצעה, הערה או בעיה? נשמח לשמוע ממך!
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <TextField
            multiline
            rows={3}
            sx={{ width: "100%" }}
            placeholder="הקלידו כאן..."
            value={formik.values.description}
            onChange={(event) =>
              formik.setFieldValue("description", event.target.value)
            }
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            helperText={formik.touched.description && formik.errors.description}
          />
          <LoadingButton
            variant="contained"
            sx={{ width: "100%", borderRadius: "12px" }}
            startIcon={<SendIcon />}
            loading={stage === "SENDING"}
            onClick={() => {
              formik.submitForm();
            }}
          >
            שליחה
          </LoadingButton>
        </Box>
      </CardContent>
    </div>
  );

  const sent = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <CheckCircleIcon sx={{ width: 80, height: 80 }} color="primary" />
      <Typography variant="h6">תודה על המשוב!</Typography>
    </Box>
  );

  const error = (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <ErrorOutlineIcon sx={{ width: 80, height: 80 }} color="primary" />
      <Typography variant="h6">נראה שמשהו השתבש...</Typography>
      <Typography variant="body1">אנא נסה לרענן את הדף</Typography>
    </Box>
  );

  const getStageContent = () => {
    switch (stage) {
      case "DRAFT":
      case "SENDING":
        return form;
      case "SENT":
        return sent;
      case "ERROR":
        return error;
    }
  };

  const handleFabClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box>
      <Card
        sx={{
          width: 400,
          borderRadius: "20px 20px 0px 20px ",
          position: "absolute",
          bottom: 88,
          right: 16,
          display: isOpen ? "block" : "none",
        }}
      >
        <CardHeader
          title="💡 תיבת פניות:)"
          sx={{ bgcolor: "primary.main" }}
          titleTypographyProps={{ sx: { color: "white" } }}
        />
        <Box sx={{ height: 270 }}>{getStageContent()}</Box>
      </Card>
      <Fab
        color="primary"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        onClick={handleFabClick}
      >
        {isOpen ? <CloseIcon /> : <ChatIcon />}
      </Fab>
    </Box>
  );
};

export default FeedbackWidget;
