import dayjs from "dayjs";
import _ from "lodash";
import { Absence } from "src/app/services/openApi";

export const absencesPerDay = (absences: Absence[]) => {
    // return an array with 30 days, and each element
    // is an array of absences that happened on that day
    const days = Array.from(
        { length: 30 },
        (_, index) => dayjs().startOf("day").subtract(29, "day").add(index, "day")
    );

    const daysWithAbsences = days.map(day => {
        const beginning = day.startOf("day");
        const ending = day.endOf("day");

        return absences.filter(absence =>
            dayjs(absence.ending).isAfter(beginning) && dayjs(absence.beginning).isBefore(ending)
        );
    });

    // for each day in daysWithAbsencesFiltered
    // return an array with the length 48, and 
    // each element is an array of absences that happened on that half hour
    const daysWithAbsencesFilteredWithHalfHours = daysWithAbsences.map(day => {
        return Array.from(
            { length: 48 },
            (_, index) => {
                if (!day[0]) return [];
                const beginning = dayjs(day[0].beginning).add(index * 30, "minute");
                const ending = dayjs(day[0].ending).add(index * 30, "minute");
                return day.filter(absence => {
                    // if the beginning or ending of the absence is in the half hour
                    return dayjs(absence.ending).isAfter(beginning) && dayjs(absence.beginning).isBefore(ending)
                });
            }
        );
    });

    // for each day return the length of the longest half hour
    const daysWithAbsencesFilteredWithHalfHoursMaxLength = daysWithAbsencesFilteredWithHalfHours.map(day => {
        return day.reduce((acc, curr) => {
            return acc.length > curr.length ? acc : curr;
        }).length;
    });

    return daysWithAbsencesFilteredWithHalfHoursMaxLength;
}

export const absencesPerHourPerDay = (absences: Absence[], quota: number) => {
    // return an array with 30 days, and each element
    // is an array of absences that happened on that day
    const days = Array.from(
        { length: 30 },
        (_, index) => ({ date: dayjs().startOf("day").add(index, "day"), absences: [] })
    );

    const daysWithAbsences = days.map(day => {
        const beginning = day.date.startOf("day");
        const ending = day.date.endOf("day");

        return {
            ...day,
            absences: absences.filter(absence =>
                dayjs(absence.ending).isAfter(beginning) && dayjs(absence.beginning).isBefore(ending)
            )
        }
    });

    // for each hour between 06:00 and 24:00
    // return an PerHourPerDayData object
    const hours = Array.from(
        { length: 24 },
        (_, index) => index
    );

    const hoursWithAbsences = hours.map(hour => {
        return {
            name: hour,
            data: daysWithAbsences.map(day => {
                const beginning = day.date.hour(hour).startOf("hour");
                const ending = day.date.hour(hour).endOf("hour");

                return {
                    // dayOfMonth
                    x: day.date.format("DD/MM"),
                    // numberOfAbsencesInThatHour
                    y: day.absences.filter(absence =>
                        dayjs(absence.ending).isAfter(beginning) && dayjs(absence.beginning).isBefore(ending)
                    ).length / quota * 100
                };
            })
        };
    });

    // sort the hoursWithAbsences by hour
    const hoursWithAbsencesSorted = _.sortBy(hoursWithAbsences, ['name']);
    // remove hours '01:00' to '06:00'
    hoursWithAbsencesSorted.splice(0, 6);

    // for each hour in hoursWithAbsencesSorted
    // format to 'HH:00'
    const hoursWithAbsencesSortedFormatted = hoursWithAbsencesSorted.map(hour => {
        return {
            ...hour,
            name: hour.name.toString().padStart(2, '0') + ':00'
        };
    });

    return hoursWithAbsencesSortedFormatted;
};