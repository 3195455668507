import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import DateAdapter from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import App from "src/app/App";
import { store } from "src/app/store";
import theme from "src/app/theme";
import RTLProvider from "src/app/theme/RTLProvider";
import { CookiesProvider } from "react-cookie";
import dayjs from "dayjs";
import "dayjs/locale/he";
import FeedbackWidget from "src/features/feedback/components/FeedbackWidget";
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAfxHOcWSw4kYOPJhTGGNvWbdIur6oRZtI",
  authDomain: "qma3-server.firebaseapp.com",
  projectId: "qma3-server",
  storageBucket: "qma3-server.appspot.com",
  messagingSenderId: "855478307755",
  appId: "1:855478307755:web:6874ed6f076fa996ae20ee",
};

export const isLocalEnv = process.env.REACT_APP_ENV === "local";
export const isProdEnv = process.env.REACT_APP_ENV === "production";

const postHogClient = posthog.init(
  "phc_keV8DsDhlKibsZQrzAdwyt2xZbA4aIvEJcAKxaYgYq5",
  {
    api_host: "https://app.posthog.com",
  }
) as any;

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firestoreDB = getFirestore(app);
if (isLocalEnv) connectFirestoreEmulator(firestoreDB, "localhost", 4001);

dayjs.locale("he");

ReactDOM.render(
  <React.StrictMode>
    <PostHogProvider client={postHogClient}>
      <CookiesProvider>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <Provider store={store}>
            <RTLProvider>
              <ThemeProvider theme={theme}>
                <BrowserRouter>
                  <App />
                  <FeedbackWidget />
                </BrowserRouter>
              </ThemeProvider>
            </RTLProvider>
          </Provider>
        </LocalizationProvider>
      </CookiesProvider>
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
