import React, { useState } from "react";
import Table, { TableColumn } from "src/app/components/Table";
import ContentLoading from "src/app/components/ContentLoading";
import { Absence } from "src/app/services/openApi";
import dayjs from "dayjs";
import AddAbsenceDialog from "src/features/attendance/components/Absences/AddAbsenceDialog";
import {
  useListAbsencesGet,
  useDelAbsencePost,
} from "src/app/services/apiHooks";
import { useAppSelector, useToken } from "src/app/hooks";
import { TimeRangeFilter } from "src/app/components/TimeRangeFilter";
import {
  TimeFilter,
  TimeFilterId,
} from "src/app/components/TimeRangeFilter/timeTypes";
import { timeFilters } from "src/app/components/TimeRangeFilter/defaultTimeFilters";
import { Box, Button, Tooltip } from "@mui/material";
import { getFormattedDate } from "src/app/utils/timeUtils";
import { getDateFormat } from "src/app/components/Dashboard/dashboardViewSlice";
import { getAuthIsStudentsUser } from "src/features/auth/authSlice";

const absencesTimeFilters = [
  timeFilters[TimeFilterId.AllNext],
  timeFilters[TimeFilterId.Today],
  timeFilters[TimeFilterId.Next7days],
  timeFilters[TimeFilterId.Next30days],
  timeFilters[TimeFilterId.Next3months],
];

const Absences: React.FC = () => {
  const token = useToken();
  const isStudentsUser = useAppSelector(getAuthIsStudentsUser);
  const [timeFilter, setTimeFilter] = useState<TimeFilter>(
    timeFilters[TimeFilterId.AllNext]
  );
  const { isLoading, data: absences } = useListAbsencesGet({
    token,
    since: dayjs(timeFilter.timeRange.startTime).format(),
    to: dayjs(timeFilter.timeRange.endTime).format(),
  });
  const [deleteAbsence] = useDelAbsencePost();
  const dateFormat = useAppSelector(getDateFormat);

  const handleFilterChange = (newFilter: TimeFilter) => {
    setTimeFilter(newFilter);
  };

  const handleRowDelete = async (absence: Absence): Promise<any> => {
    deleteAbsence({
      token,
      absenceId: absence.ID,
    });
  };

  if (isLoading) {
    return <ContentLoading />;
  }

  const columns: TableColumn<Absence>[] = [
    { title: "שם התלמיד", field: "student.name", type: "string" },
    {
      title: "זמן קליטת הבקשה",
      field: "received",
      type: "datetime",
      render: (rowData) => getFormattedDate(rowData.received, dateFormat),
    },
    {
      title: "זמן התחלה",
      field: "beginning",
      type: "datetime",
      defaultSort: "asc",
      render: (rowData) => getFormattedDate(rowData.beginning, dateFormat),
    },
    {
      title: "זמן סיום",
      field: "ending",
      type: "datetime",
      render: (rowData) => getFormattedDate(rowData.ending, dateFormat),
    },
    {
      title: "מספר טלפון",
      field: "student.phone_num",
      type: "string",
      render: (rowData) => (
        <Tooltip title="לחץ כדי להתקשר">
          <Button
            onClick={() => {
              window.open(`tel:${rowData.student.phone_num}`);
            }}
          >
            {rowData.student.phone_num}
          </Button>
        </Tooltip>
      ),
    },
  ];

  return (
    <Table
      title="העדרויות"
      columns={columns}
      onRowDelete={!isStudentsUser ? handleRowDelete : undefined}
      toolbarComponents={{
        left: (
          <Box display="flex" gap={1}>
            <TimeRangeFilter
              timeFilters={absencesTimeFilters}
              currentFilter={timeFilter}
              setFilter={handleFilterChange}
            />
            {!isStudentsUser && <AddAbsenceDialog />}
          </Box>
        ),
      }}
      data={absences ?? []}
      pageSize={20}
      toolbarActions={{
        isWithExport: !isStudentsUser,
      }}
    />
  );
};

export default Absences;
