import React from "react";
import { Column, MTableToolbar } from "@material-table/core";
import { SaveAlt } from "@mui/icons-material";
import { Box, Divider } from "@mui/material";
import TableIconButton from "src/app/components/Table/TableIconButton";
import _ from "lodash";
import { ToolbarComponents } from "src/app/components/Table";

// exports material table to csv file and downloads it
const exportToCsv = (columns: Column<any>[], data: object[]) => {
  // convert to rtl
  columns.reverse();

  let csv = data
    .map((row) =>
      columns.map((column) => {
        // @ts-ignore
        const value = _.get(row, column.field);
        return value;
      })
    )
    .map((row) => row.join(","))
    .join("\n");
  const columnsTitles = columns.map((column) => column.title);
  csv = columnsTitles.join(",") + "\n" + csv;

  // add UTF-8 BOM to beginning so excel doesn't get confused.
  var BOM = String.fromCharCode(0xfeff);
  csv = BOM + csv;

  const csvFile = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const downloadLink = document.createElement("a");
  downloadLink.download = "export.csv";
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.click();
};

type Props = {
  data: any[];
  columns: Column<any>[];
  tableTitle: string;
  MaterialTableProps: any;
  components?: ToolbarComponents;
  isWithExport?: boolean;
};

const TableToolbar: React.FC<Props> = ({
  data,
  columns,
  MaterialTableProps,
  components,
  isWithExport,
}) => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 2,
          pr: 2,
        }}
      >
        <MTableToolbar {...MaterialTableProps} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {components?.right && components?.right}
          {isWithExport && (
            <TableIconButton
              tooltip="יצוא"
              onClick={() => exportToCsv(columns, data)}
              sx={{ mr: 1 }}
            >
              <SaveAlt />
            </TableIconButton>
          )}
          {components?.left && components?.left}
        </Box>
      </Box>
      <Divider sx={{ mt: 2 }} />
    </div>
  );
};

export default TableToolbar;
