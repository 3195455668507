import { useMediaQuery } from "@mui/material";
import { useCookies } from "react-cookie";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import type { AppState, AppDispatch } from "src/app/store";
import { AUTH_TOKEN_COOKIE } from "src/features/auth/types";

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useToken = () => {
    const [cookies] = useCookies([AUTH_TOKEN_COOKIE]);
    return cookies[AUTH_TOKEN_COOKIE];
}

export const useTabLocation = () => {
    const { pathname } = useLocation();
    const tabLocation = pathname.split("/")[2];
    return tabLocation;
}

export const useIsDesktop = () => {
    const desktopModeMediaQuery = '@media (pointer: fine)';
    return useMediaQuery(desktopModeMediaQuery);
}