import React from "react";
import { Grid } from "@mui/material";
import SystemMessages from "src/features/settings/components/SettingsView/AttendanceSettings/SystemMessages";
import GroupsLimitations from "src/features/settings/components/SettingsView/AttendanceSettings/GroupsLimitations";
import GeneralLimitations from "src/features/settings/components/SettingsView/AttendanceSettings/GeneralLimitations";

const AttendanceSettings: React.FC = () => {
  return (
    <div>
      <Grid item xs={12}>
        <GeneralLimitations />
      </Grid>
      <Grid item xs={12}>
        <SystemMessages />
      </Grid>
      <Grid item xs={12}>
        <GroupsLimitations />
      </Grid>
    </div>
  );
};

export default AttendanceSettings;
