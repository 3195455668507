import React from "react";
import { TabPanel as MuiTabPanel } from "@mui/lab";
import { Grid } from "@mui/material";

type Props = {
  children: React.ReactNode;
  value: string;
};

const TabPanel: React.FC<Props> = ({ value, children }) => {
  return (
    <MuiTabPanel value={value} sx={{ pl: 0, pr: 0, height: "100%" }}>
      <Grid container spacing={2} sx={{ height: "100%" }}>
        {children}
      </Grid>
    </MuiTabPanel>
  );
};

export default TabPanel;
