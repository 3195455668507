import React, { useState } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import HorizontalCard from "src/features/settings/components/HorizontalCard";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMsgFormatGet, useMsgFormatPost } from "src/app/services/apiHooks";
import { MsgTypes } from "src/app/services/openApi";
import { useToken } from "src/app/hooks";

const SystemMessages: React.FC = () => {
  const [currMessageType, setCurrMessageType] = useState<MsgTypes>(
    systemMessagesMap[0].type
  );
  const currMessageInfo =
    systemMessagesMap.find(
      (systemMessage) => systemMessage.type === currMessageType
    )?.tooltip ?? "";
  const token = useToken();
  const { data: msgFormat } = useMsgFormatGet({
    token,
    sub: currMessageType,
  });
  const [setMsgFormat] = useMsgFormatPost();

  return (
    <HorizontalCard title="הודעות מערכת">
      <Grid container spacing={2} direction="column" mt={0.5}>
        <Grid item>
          <FormControl sx={{ flexGrow: 1 }}>
            <InputLabel>סוג ההודעה</InputLabel>
            <Select<MsgTypes>
              value={currMessageType}
              label="סוג ההודעה"
              onChange={(event) => {
                return setCurrMessageType(event.target.value as MsgTypes);
              }}
            >
              {systemMessagesMap.map((systemMessage) => (
                <MenuItem value={systemMessage.type} key={systemMessage.type}>
                  {systemMessage.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Tooltip title={currMessageInfo}>
            <InfoOutlinedIcon
              color="primary"
              sx={{ ml: 2, mt: "auto", mb: "auto" }}
              fontSize="large"
            />
          </Tooltip>
        </Grid>
        <Grid item>
          <TextField
            label="תוכן ההודעה"
            multiline
            rows={4}
            placeholder="הקלידו כאן..."
            value={msgFormat ?? ""}
            sx={{ width: "100%" }}
            onChange={(event) => {
              setMsgFormat({
                token,
                sub: currMessageType,
                msgFormat: event.target.value,
              });
            }}
          />
        </Grid>
      </Grid>
    </HorizontalCard>
  );
};

export default SystemMessages;

type SystemMessage = {
  type: MsgTypes;
  title: string;
  tooltip: string;
};

const systemMessagesMap: SystemMessage[] = [
  /**
   * okay - Student request approved.
   */
  {
    type: "okay",
    title: "הבקשה אושרה",
    tooltip: "בקשת התלמיד אושרה",
  },
  /**
   * reject - The student's request was denied and entered on the waiting list.
   */
  {
    type: "reject",
    title: "הבקשה נדחתה",
    tooltip: "בקשת התלמיד נדחתה ונוספה לרשימת ההמתנה",
  },
  /**
   * cencel - Student's request has been deleted.
   */
  {
    type: "cencel",
    title: "הבקשה נמחקה",
    tooltip: "בקשת התלמיד נמחקה",
  },
  /**
   * reminder - The student must return to the institution soon.
   */
  {
    type: "reminder",
    title: "תזכורת",
    tooltip: "תזכורת - התלמיד חייב לחזור למוסד בקרוב",
  },
  /**
   * impermissible - The request is rejected because it exceeds the monthly/weekly limit of the institution.
   */
  {
    type: "impermissible",
    title: "חריגה מכמות השעות המותרת",
    tooltip: "הבקשה נדחתה - חריגה מכמות השעות המותרת",
  },
  /**
   * illegal - In response to a message that is not understood.
   */
  {
    type: "illegal",
    title: "הודעה לא תקינה",
    tooltip: "בתגובה לבקשה לא תקינה - בקשה לא לפי הפורמט",
  },
  /**
   * pend - Will send to the student when a time slot becomes available for which the student has sent a request in the past.
   */
  {
    type: "pend",
    title: "עדכון - רשימת המתנה",
    tooltip: "ישלח לתלמיד כאשר תתפנה משבצת זמן שהתלמיד שלח לה בקשה בעבר",
  },
];
