import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar as MuiAppBar,
  Box,
  Divider,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import HelpIcon from "@mui/icons-material/Help";
import Account from "src/app/components/Dashboard/AppBar/AccountMenu";
import ChatIcon from "@mui/icons-material/Chat";
import DateFormatToggle from "src/app/components/Dashboard/AppBar/DateFormatToggle";
import { getAuthIsStudentsUser } from "src/features/auth/authSlice";
import { useAppSelector } from "src/app/hooks";

type Props = {
  isMobileNavMenuOpen: boolean;
  setIsMobileNavMenuOpen: (isMobileNavMenuOpen: boolean) => void;
};

const AppBar: React.FC<Props> = ({
  isMobileNavMenuOpen,
  setIsMobileNavMenuOpen,
}) => {
  const isStudentsUser = useAppSelector(getAuthIsStudentsUser);

  const handleMobileNavMenuToggle = () => {
    setIsMobileNavMenuOpen(!isMobileNavMenuOpen);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MuiAppBar
        position="fixed"
        variant="outlined"
        elevation={0}
        color="transparent"
        sx={{
          borderLeft: "0px",
          backdropFilter: "blur(20px)",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleMobileNavMenuToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <DateFormatToggle />
          {!isStudentsUser && (
            <div>
              <Tooltip title="תמיכה">
                <Link
                  href="https://qma.co.il/support/"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton color="primary" sx={{ ml: 2 }}>
                    <HelpIcon />
                  </IconButton>
                </Link>
              </Tooltip>
              <Tooltip title="יצירת קשר">
                <Link
                  href="https://qma.co.il/contact/"
                  target="_blank"
                  rel="noopener"
                >
                  <IconButton color="primary" sx={{ ml: 2 }}>
                    <ChatIcon />
                  </IconButton>
                </Link>
              </Tooltip>
            </div>
          )}
          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
          <Account />
        </Toolbar>
      </MuiAppBar>
    </Box>
  );
};

export default AppBar;
