import React from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import {
  getDateFormat,
  setDateFormat,
} from "src/app/components/Dashboard/dashboardViewSlice";
import { DateFormat } from "src/app/components/Time/types";

const DateFormatToggle = () => {
  const dispatch = useAppDispatch();
  const dateFormat = useAppSelector(getDateFormat);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    format: DateFormat
  ) => {
    dispatch(setDateFormat(format));
  };

  return (
    <Tooltip title="פורמט תאריכים">
      <ToggleButtonGroup
        value={dateFormat}
        exclusive
        onChange={handleAlignment}
        size="small"
      >
        <ToggleButton value="HEBREW">עברי</ToggleButton>
        <ToggleButton value="ENGLISH">לועזי</ToggleButton>
      </ToggleButtonGroup>
    </Tooltip>
  );
};

export default DateFormatToggle;
