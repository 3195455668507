import React from "react";
import { useToken } from "src/app/hooks";
import { useRegulationGet, useRegulationPost } from "src/app/services/apiHooks";
import {
  Typography,
  Grid,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import HorizontalCard from "src/features/settings/components/HorizontalCard";
import AttendanceTimes from "src/features/settings/components/SettingsView/AttendanceSettings/AttendanceTimes";
import Quota from "src/features/settings/components/SettingsView/AttendanceSettings/Quota";

const GeneralLimitations = () => {
  const token = useToken();
  const { data: regulation, isLoading } = useRegulationGet({ token });
  const [setRegulation] = useRegulationPost();

  const handleGranularityChange = (
    _event: React.MouseEvent<HTMLElement>,
    isMonthly: boolean
  ) => {
    if (regulation)
      setRegulation({
        token,
        regulationSetting: {
          ...regulation,
          monthly_regulation: isMonthly,
        },
      });
  };

  const handleHoursChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (regulation)
      setRegulation({
        token,
        regulationSetting: {
          ...regulation,
          regulation: parseInt(event.target.value),
        },
      });
  };

  if (isLoading) return null;

  return (
    <HorizontalCard title="מגבלה כללית">
      <Grid container spacing={2} direction="column" mt={0.5}>
        <AttendanceTimes />
        <Quota />
        <Grid container item spacing={2} direction="column">
          <Grid item>
            <Typography variant="body1">מספר שעות העדרות לתלמיד</Typography>
          </Grid>
          <Grid container item spacing={2}>
            <Grid item flexGrow={1}>
              <TextField
                label="מספר שעות"
                variant="outlined"
                type="number"
                inputProps={{ min: "0" }}
                sx={{ width: "100%" }}
                value={regulation?.regulation}
                onChange={handleHoursChange}
              />
            </Grid>
            <Grid item>
              <ToggleButtonGroup
                color="primary"
                value={regulation?.monthly_regulation}
                exclusive
                onChange={handleGranularityChange}
                sx={{
                  height: "100%",
                }}
              >
                <ToggleButton value={false}>שבועיות</ToggleButton>
                <ToggleButton value={true}>חודשיות</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </HorizontalCard>
  );
};

export default GeneralLimitations;
