import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useToken } from "src/app/hooks";
import { useGroupsNamesGet } from "src/app/services/apiHooks";
import _ from "lodash";
import { FormHelperText } from "@mui/material";

type Props = {
  group: number;
  onChange?: (newGroupId: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
};

const GroupSelect: React.FC<Props> = ({
  group,
  error,
  helperText,
  onChange,
}) => {
  const token = useToken();
  const { data: groupsNames } = useGroupsNamesGet({
    token,
  });

  const handleChange = (event: SelectChangeEvent<number>) => {
    const {
      target: { value },
    } = event;

    if (!onChange) {
      console.error("error(PermissionsSelect): onChange is not defined");
      return;
    }

    onChange(value);
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth error={error}>
        <InputLabel>קבוצה</InputLabel>
        <Select value={group} label="קבוצה" onChange={handleChange}>
          <MenuItem value={-1}>
            <em>ללא קבוצה</em>
          </MenuItem>
          {groupsNames?.map((groupName) => (
            <MenuItem key={groupName.serial} value={groupName.serial}>
              {groupName.nickname}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
};

export default GroupSelect;
