import dayjs from "dayjs";
import { TimeFilter, TimeFilterId } from "src/app/components/TimeRangeFilter/timeTypes";

const startOfToday = dayjs().startOf('day').toDate();
const endOfToday = dayjs().endOf('day').toDate();

export const timeFilters: {
    [key: string]: TimeFilter;
} = {
    [TimeFilterId.Today]: {
        label: "היום",
        id: TimeFilterId.Today,
        timeRange: {
            startTime: startOfToday,
            endTime: dayjs(startOfToday).endOf('day').toDate(),
        },
    },
    [TimeFilterId.Last7Days]: {
        label: "7 הימים האחרונים",
        id: TimeFilterId.Last7Days,
        timeRange: {
            startTime: dayjs(startOfToday).subtract(7, "day").toDate(),
            endTime: endOfToday,
        },
    },
    [TimeFilterId.Last30Days]: {
        label: "30 הימים האחרונים",
        id: TimeFilterId.Last30Days,
        timeRange: {
            startTime: dayjs(startOfToday).subtract(30, "day").toDate(),
            endTime: endOfToday,
        },
    },
    [TimeFilterId.last3months]: {
        label: "3 החודשים האחרונים",
        id: TimeFilterId.last3months,
        timeRange: {
            startTime: dayjs(startOfToday).subtract(3, "month").toDate(),
            endTime: endOfToday,
        },
    },
    [TimeFilterId.AllNext]: {
        label: "הכל (עתידיים)",
        id: TimeFilterId.AllNext,
        timeRange: {
            startTime: startOfToday,
            endTime: dayjs(endOfToday).add(1, "year").toDate(),
        },
    },
    [TimeFilterId.Next7days]: {
        label: "7 הימים הבאים",

        id: TimeFilterId.Next7days,
        timeRange: {
            startTime: startOfToday,
            endTime: dayjs(endOfToday).add(7, "day").toDate(),
        },
    },
    [TimeFilterId.Next30days]: {
        label: "30 הימים הבאים",
        id: TimeFilterId.Next30days,
        timeRange: {
            startTime: startOfToday,
            endTime: dayjs(endOfToday).add(30, "day").toDate(),
        },
    },
    [TimeFilterId.Next3months]: {
        label: "3 החודשים הבאים",
        id: TimeFilterId.Next3months,
        timeRange: {
            startTime: startOfToday,
            endTime: dayjs(endOfToday).add(3, "month").toDate(),
        },
    },
};
