import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Stack, TextField } from "@mui/material";
import { useState } from "react";
import ContentLoading from "src/app/components/ContentLoading";
import * as yup from "yup";
import { useFormik } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useToken } from "src/app/hooks";
import GroupSelect from "src/features/settings/components/SettingsView/StudentsSettings/GroupSelect";
import {
  useStudentsListGet,
  useStudentsListPost,
} from "src/app/services/apiHooks";

const validationSchema = yup.object({
  name: yup
    .string()
    .required("שדה חובה")
    .min(5, "שם חייב להכיל שם פרטי ושם משפחה"),
  phone_num: yup
    .string()
    .required("שדה חובה")
    .min(10, "מספר טלפון לא תקין")
    .max(10, "מספר טלפון לא תקין"),
  group: yup.number().required("חובה לבחור קבוצה"),
});

const AddStudentDialog: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const token = useToken();
  const [setStudentsList] = useStudentsListPost();
  const { data: students } = useStudentsListGet({
    token,
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone_num: "",
      group: -1,
    },
    validationSchema: validationSchema,
    onSubmit: async (newStudent) => {
      if (!students) {
        console.error("students is null");
        return;
      }
      try {
        setIsOpen(false);
        await setStudentsList({
          token,
          body: [
            ...students,
            {
              name: newStudent.name,
              phone_num: newStudent.phone_num,
              group: newStudent.group === -1 ? undefined : newStudent.group,
            },
          ],
        });
        formik.resetForm();
      } catch (error) {
        console.error("rejected", error);
      }
    },
  });

  const isDialogLoading = false;

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCancel = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  const Loading = (
    <DialogContent
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <ContentLoading />
    </DialogContent>
  );

  const Form = (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <DialogContent>
        <Stack spacing={2}>
          <TextField
            label="שם התלמיד"
            variant="outlined"
            value={formik.values.name}
            onChange={(event) => {
              formik.setFieldValue("name", event.target.value);
            }}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          <TextField
            label="מספר טלפון"
            variant="outlined"
            value={formik.values.phone_num}
            onChange={(event) => {
              formik.setFieldValue("phone_num", event.target.value);
            }}
            error={formik.touched.phone_num && Boolean(formik.errors.phone_num)}
            helperText={formik.touched.phone_num && formik.errors.phone_num}
          />
          <GroupSelect
            group={formik.values.group}
            onChange={(newGroup) => {
              formik.setFieldValue("group", newGroup);
            }}
            error={formik.touched.group && Boolean(formik.errors.group)}
            helperText={formik.touched.group && formik.errors.group}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          pr: 2,
          pb: 2,
        }}
      >
        <Button onClick={handleCancel}>ביטול</Button>
        <Button
          variant="contained"
          onClick={() => {
            formik.submitForm();
          }}
        >
          הוספה
        </Button>
      </DialogActions>
    </Box>
  );

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleOpen}
        startIcon={<AddCircleOutlineIcon />}
      >
        הוספה
      </Button>
      {isOpen && (
        <Dialog open={isOpen}>
          <DialogTitle>הוספת תלמיד</DialogTitle>
          <Box
            sx={{
              width: "300px",
              height: "fit-content",
            }}
          >
            {isDialogLoading ? Loading : Form}
          </Box>
        </Dialog>
      )}
    </div>
  );
};

export default AddStudentDialog;
