import * as React from "react";
import { Link, useMatch, useResolvedPath } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { Chip, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavItem } from "src/app/components/Dashboard/types";

type Props = {
  navItem: NavItem;
};

const NavMenuLink = ({ navItem, ...props }: Props) => {
  const resolved = useResolvedPath(navItem.path);
  const isPathMatching = useMatch({ path: resolved.pathname, end: false });

  return (
    <div>
      <Link
        style={{ textDecoration: "none" }}
        to={navItem.isBeta ? "#" : navItem.path}
        {...props}
      >
        <ListItem
          button
          key={navItem.label}
          dense
          disabled={navItem.isBeta}
          sx={{
            backgroundColor: isPathMatching ? "primary.extraLight" : "",
            borderRadius: 1,
            width: (theme) => theme.spacing(28),
            mb: 0.5,
            mr: 0.5,
            ml: 0.5,
          }}
        >
          <ListItemIcon
            sx={{ color: isPathMatching ? "primary.main" : "text.secondary" }}
          >
            {navItem.icon}
          </ListItemIcon>
          <ListItemText
            // primary={navItem.label}
            sx={{
              color: isPathMatching ? "text.primary" : "text.secondary",
            }}
            primaryTypographyProps={{
              variant: "button",
            }}
          >
            {navItem.label}
            {navItem.isBeta && (
              <Chip
                label="בקרוב"
                size="small"
                sx={{
                  marginLeft: 1,
                }}
              />
            )}
          </ListItemText>
        </ListItem>
      </Link>
    </div>
  );
};

export default NavMenuLink;
