import React from "react";
import { useCookies } from "react-cookie";
import { Navigate, useLocation } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import { AUTH_TOKEN_COOKIE, USERNAME_COOKIE } from "src/features/auth/types";
import { RoutingPath } from "src/features/routing/types";
import logo from "src/app/components/logo.svg";
import { LOGO_FILTER } from "src/app/theme";
import { usePermissionsGet } from "src/app/services/apiHooks";
import { useDispatch } from "react-redux";
import { setCredentials } from "src/features/auth/authSlice";
import { useToken } from "src/app/hooks";
import { usePostHog } from "posthog-js/react";

type Props = {
  children: JSX.Element;
};

const RequireAuth: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [cookies] = useCookies([AUTH_TOKEN_COOKIE, USERNAME_COOKIE]);
  const token = useToken();
  const { isLoading, data: permissions } = usePermissionsGet({
    token,
  });
  const posthog = usePostHog();


  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            maxHeight: 100,
            mb: 2,
            filter: LOGO_FILTER,
          }}
        />
        <CircularProgress />
      </Box>
    );
  }

  if (!permissions) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate to={RoutingPath.SignIn} state={{ from: location }} replace />
    );
  }

  posthog.identify(cookies[USERNAME_COOKIE]);
  dispatch(
    setCredentials({
      token: cookies[AUTH_TOKEN_COOKIE],
      username: cookies[USERNAME_COOKIE],
    })
  );
  return children;
};

export default RequireAuth;
