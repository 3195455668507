import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import logo from "src/app/components/logo.svg";
import { Card, CircularProgress } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAppDispatch } from "src/app/hooks";
import { setCredentials } from "src/features/auth/authSlice";
import { useCookies } from "react-cookie";
import { AUTH_TOKEN_COOKIE, USERNAME_COOKIE } from "src/features/auth/types";
import { LOGO_FILTER } from "src/app/theme";
import { useLoginPost, usePermissionsGet } from "src/app/services/apiHooks";
import { RoutingPath } from "src/features/routing/types";
import { usePostHog } from "posthog-js/react";

const validationSchema = yup.object({
  username: yup.string().required("יש להזין שם משתמש"),
  password: yup
    .string()
    .min(5, "יש להזין סיסמה בת 5 תווים לפחות")
    .required("יש להזין סיסמה"),
});

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"כל הזכויות שמורות © "}
      <Link color="inherit" href="https://qma.co.il/">
        קיומה
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const SignIn: React.FC = () => {
  const [login, { isLoading: isLoginLoading }] = useLoginPost();
  const [cookies, setCookie] = useCookies([AUTH_TOKEN_COOKIE, USERNAME_COOKIE]);
  const { isLoading: isPermissionsLoading, data: permissions } =
    usePermissionsGet({
      token: cookies[AUTH_TOKEN_COOKIE],
    });
  const dispatch = useAppDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const posthog = usePostHog();

  // @ts-ignore
  let from = location.state?.from?.pathname || "/";

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (credentials) => {
      try {
        setIsLoginFailed(false);
        const mutationPayload = { login: credentials };
        const token = await login(mutationPayload).unwrap();

        posthog.identify(credentials.username);
        dispatch(setCredentials({ token, username: credentials.username }));
        setCookie(AUTH_TOKEN_COOKIE, token, { path: "/" });
        setCookie(USERNAME_COOKIE, credentials.username, { path: "/" });

        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(from, { replace: true });
      } catch (error) {
        console.error(error);
        setIsLoginFailed(true);
      }
    },
  });

  const getButtonContent = () => {
    if (isLoginLoading) {
      return <CircularProgress size={20} sx={{ color: "#fff" }} />;
    }
    return "להתחברות";
  };

  if (isPermissionsLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={logo}
          sx={{
            maxHeight: 100,
            mb: 2,
            filter: LOGO_FILTER,
          }}
        />
        <CircularProgress />
      </Box>
    );
  }

  if (permissions) {
    posthog.identify(cookies[USERNAME_COOKIE]);
    dispatch(
      setCredentials({
        token: cookies[AUTH_TOKEN_COOKIE],
        username: cookies[USERNAME_COOKIE],
      })
    );
    return <Navigate to={RoutingPath.Home} replace />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Card
          variant="outlined"
          sx={{
            padding: 4.5,
            width: 450,
            height: 500,
            borderWidth: {
              xs: 0,
              md: 1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={logo}
              sx={{
                maxHeight: 24,
                mb: 2,
                filter: LOGO_FILTER,
              }}
            />
            <Typography component="h1" variant="h5">
              התחברות
            </Typography>
            <Box
              component="form"
              onSubmit={formik.handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="שם משתמש"
                name="username"
                autoComplete="username"
                autoFocus
                value={formik.values.username}
                onChange={formik.handleChange}
                disabled={isLoginLoading}
                error={
                  formik.touched.username && Boolean(formik.errors.username)
                }
                helperText={formik.touched.username && formik.errors.username}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="סיסמה"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                disabled={isLoginLoading}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
              <FormControlLabel
                control={
                  <Checkbox value="remember" color="primary" defaultChecked />
                }
                label="השאר מחובר"
              />
              {isLoginFailed && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography variant="caption" color="error">
                    שם משתמש או סיסמה שגויים
                  </Typography>
                </Box>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: isLoginFailed ? 1 : 2.75, mb: 2, height: 36.5 }}
              >
                {getButtonContent()}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link
                    href="https://qma.co.il/contact/"
                    target="_blank"
                    variant="body2"
                    rel="noopener"
                  >
                    שכחת סיסמה?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    href="https://qma.co.il/contact/"
                    target="_blank"
                    variant="body2"
                    rel="noopener"
                  >
                    להרשמה
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Card>
        <Copyright sx={{ mt: 4, mb: 4 }} />
      </Box>
    </Container>
  );
};

export default SignIn;
