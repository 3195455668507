import { IconButton, useTheme, Tooltip, SxProps, Theme } from "@mui/material";

import React from "react";

type Props = {
  children: React.ReactNode;
  tooltip?: string;
  variant?: "text" | "contained" | "outlined";
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  sx?: SxProps<Theme>;
};

const TableIconButton: React.FC<Props> = ({
  tooltip,
  children,
  variant = "outlined",
  onClick,
  sx = {},
}) => {
  const theme = useTheme();
  const containedStyle = {
    borderRadius: 1,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary[700],
    },
  };
  const outlinedStyle = {
    borderStyle: `solid`,
    borderWidth: 1,
    borderRadius: 1,
    borderColor: "text.secondary",
    color: "text.secondary",
    "&:hover": {
      borderColor: "primary.main",
      background: theme.palette.grey[50],
    },
  };
  const textStyle = {
    color: "text.secondary",
    "&:hover": {
      color: "text.primary",
    },
  };

  const getStyle = () => {
    switch (variant) {
      case "contained":
        return containedStyle;
      case "outlined":
        return outlinedStyle;
      case "text":
        return textStyle;
    }
  };

  return (
    <Tooltip title={tooltip ?? ""}>
      <IconButton
        onClick={onClick}
        sx={{
          ml: 1,
          height: 36.5,
          width: 36.5,
          ...getStyle(),
          ...sx,
        }}
      >
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default TableIconButton;
