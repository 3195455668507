import { Box } from "@mui/material";
import React from "react";

type Props = {
  children: React.ReactNode;
};

export const ViewBase: React.FC<Props> = ({ children }) => {
  return <Box sx={{ height: "fit-content", pb: 4 }}>{children}</Box>;
};
