import React from "react";
import { Grid } from "@mui/material";
import PieQuickStats from "src/features/attendance/components/AttendanceDashboard/AttendancePie";
import RiskMeter from "src/features/attendance/components/AttendanceDashboard/RiskMeter";
import AttendanceHeatmap from "src/features/attendance/components/AttendanceDashboard/AttendanceHeatmap";
import AttendanceOverTime from "src/features/attendance/components/AttendanceDashboard/AttendanceOverTime";
import ActivityList from "src/features/attendance/components/AttendanceDashboard/ActivityList";
import MessagesCount from "src/features/attendance/components/AttendanceDashboard/MessagesCount";
import {
  useListAbsencesGet,
  useStudentsListGet,
} from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import ContentLoading from "src/app/components/ContentLoading";
import {
  get29DaysAgo,
  get29DaysAhead,
  getCurrentTime,
  getEndOfToday,
  getStartOfToday,
  getTomorrow,
} from "src/app/utils/timeUtils";

export const TWENTY_NINE_DAYS_AGO = get29DaysAgo();
export const TWENTY_NINE_DAYS_AHEAD = get29DaysAhead();
export const CURRENT_TIME = getCurrentTime();
export const TOMORROW = getTomorrow();
export const START_OF_TODAY = getStartOfToday();
export const END_OF_TODAY = getEndOfToday();

const AttendanceDashboard: React.FC = () => {
  const token = useToken();
  const { isLoading: isLoadingAbsences } = useListAbsencesGet({
    token,
    since: TWENTY_NINE_DAYS_AGO,
    to: TWENTY_NINE_DAYS_AHEAD,
  });
  const { isLoading: isLoadingStudents } = useStudentsListGet(
    {
      token,
    },
    {
      selectFromResult: (response) => ({
        numOfStudents: response?.data?.length,
        ...response,
      }),
    }
  );

  if (isLoadingAbsences || isLoadingStudents) {
    return <ContentLoading />;
  }

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <RiskMeter />
        <MessagesCount />
      </Grid>
      <Grid item xs={12} md={4}>
        <PieQuickStats />
      </Grid>
      <Grid item xs={12} md={4}>
        <ActivityList />
      </Grid>
      <Grid item xs={12}>
        <AttendanceOverTime />
      </Grid>
      <Grid item xs={12}>
        <AttendanceHeatmap />
      </Grid>
    </Grid>
  );
};

export default AttendanceDashboard;
