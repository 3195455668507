import { doc, setDoc } from "firebase/firestore";
import { firestoreDB } from "src";
import { Feedback } from "src/features/feedback/model";
import { v4 as uuidv4 } from 'uuid';

enum Collection {
    Feedback = "feedback",
}

export const addFeedback = async (feedback: Feedback) => {
    try {
        const id = uuidv4();
        await setDoc(doc(firestoreDB, Collection.Feedback, id), { id, ...feedback });
    } catch (error: any) {
        throw new Error(error);
    }
}