import React, { useCallback, useRef, useState } from "react";
import { Button, ListItemText, Menu, MenuItem } from "@mui/material";
import TodayIcon from "@mui/icons-material/Today";
import { TimeFilter } from "src/app/components/TimeRangeFilter/timeTypes";
import CustomTimeFilter from "src/app/components/TimeRangeFilter/CustomTimeFilter";

type Props = {
  timeFilters: TimeFilter[];
  currentFilter: TimeFilter;
  setFilter: (timeRange: TimeFilter) => void;
};

export const TimeRangeFilter: React.FC<Props> = ({ timeFilters, currentFilter, setFilter }) => {
  const actionRef = useRef(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const width = 20;

  const handleMenuItemClick = useCallback((newFilter: TimeFilter) => {
    setFilter(newFilter);
    setMenuOpen(true);
  }, [setFilter]);

  return (
    <div>
      <Button
        ref={actionRef}
        onClick={() => setMenuOpen(true)}
        variant="outlined"
        startIcon={<TodayIcon />}
        sx={{
          color: "text.secondary",
          borderColor: "text.secondary",
          minWidth: (theme) => theme.spacing(width),
          "&.MuiButton-root": {
            justifyContent: "flex-start",
          },
        }}
      >
        {currentFilter.label}
      </Button>
      <Menu
        anchorEl={actionRef.current}
        onClose={() => setMenuOpen(false)}
        open={isMenuOpen}
        PaperProps={{
          sx: {
            width: (theme) => theme.spacing(width),
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {timeFilters.map((filterOption) => (
          <MenuItem
            key={filterOption.id}
            onClick={() => handleMenuItemClick(filterOption)}
            selected={currentFilter.id === filterOption.id}
          >
            <ListItemText primary={filterOption.label} />
          </MenuItem>
        ))}
        <CustomTimeFilter currentFilter={currentFilter} setFilter={setFilter} />
      </Menu>
    </div>
  );
};
