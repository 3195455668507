export type TimeRange = {
    startTime?: Date;
    endTime?: Date;
};

export type TimeFilter = {
    label: string;
    id: TimeFilterId;
    timeRange: TimeRange;
};

export enum TimeFilterId {
    Today = 'today',
    Last7Days = 'last7days',
    Last30Days = 'last30days',
    last3months = 'last3months',
    Next7days = 'next7days',
    Next30days = 'next30days',  
    Next3months = 'next3months',
    AllNext = 'allNext',
    Custom = 'custom',
}