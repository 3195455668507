import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, Tab } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import TabPanel from "src/app/components/TabPanel";
import Absences from "src/features/attendance/components/Absences";
import AttendanceDashboard from "src/features/attendance/components/AttendanceDashboard";
import { ViewBase } from "src/app/components/ViewBase";
import { useTabLocation } from "src/app/hooks";
import TabLink from "src/app/components/TabLink";

const AttendanceView = () => {
  const tabLocation = useTabLocation();

  return (
    <ViewBase>
      <Typography variant="h2" sx={{ mb: 4, mt: 2 }}>
        נוכחות
      </Typography>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabLocation ?? ""}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList>
              <TabLink label="מבט על" value="general" />
              <TabLink label="העדרויות" value="absences" />
            </TabList>
          </Box>
          <TabPanel value="general">
            <Grid item xs={12}>
              <AttendanceDashboard />
            </Grid>
          </TabPanel>
          <TabPanel value="absences">
            <Grid item xs={12}>
              <Absences />
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
    </ViewBase>
  );
};

export default AttendanceView;
