import React from "react";
import { Grid } from "@mui/material";
import BasicDetails from "src/features/settings/components/SettingsView/GeneralSettings/BasicDetails";
import Subscription from "src/features/settings/components/SettingsView/GeneralSettings/Subscription";
import Users from "src/features/settings/components/SettingsView/GeneralSettings/Users";

const GeneralSettings: React.FC = () => {
  
  return (
    <div>
      <Grid item xs={12}>
        <BasicDetails />
      </Grid>
      {/* // FEATURE TOGGLE - TODO: Decide in subscription plans */}
      {/* <Grid item xs={12}>
        <Subscription />
      </Grid> */}
      <Grid item xs={12}>
        <Users />
      </Grid>
    </div>
  );
};

export default GeneralSettings;
