import React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Permission } from "src/app/services/openApi";
import {
  getDisplayPermissions,
  getPermissionsObject,
  relevantPermissionsValues,
} from "src/features/settings/components/SettingsView/GeneralSettings/Users/utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

type Props = {
  permissions: Permission;
  disabled?: boolean;
  onChange?: (newPermissions: any) => void;
};

const PermissionsSelect: React.FC<Props> = ({
  permissions,
  disabled,
  onChange,
}) => {
  const displayPermissions = getDisplayPermissions(permissions);

  const handleChange = (
    event: SelectChangeEvent<typeof displayPermissions>
  ) => {
    const {
      target: { value },
    } = event;

    // On autofill we get a stringified value.
    const newPermissionsArray = typeof value === "string" ? value.split(",") : value;

    if (!onChange) {
        console.error("error(PermissionsSelect): onChange is not defined");
        return;
    }

    const permissionsObject = getPermissionsObject(newPermissionsArray, permissions);

    onChange(permissionsObject);
  };

  return (
    <FormControl sx={{ m: 1, width: 400 }}>
      <Select
        multiple
        displayEmpty
        value={displayPermissions}
        onChange={handleChange}
        MenuProps={MenuProps}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>ללא הרשאות</em>;
          }

          return selected.join(", ");
        }}
      >
        {relevantPermissionsValues.map((permission) => (
          <MenuItem key={permission} value={permission} disabled={disabled}>
            <Checkbox checked={displayPermissions.indexOf(permission) > -1} />
            <ListItemText primary={permission} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PermissionsSelect;
