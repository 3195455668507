import React from "react";
import Table from "src/app/components/Table";
import { Column } from "@material-table/core";
import { User } from "src/app/services/openApi";
import { useUsersListGet, useEditUserPost } from "src/app/services/apiHooks";
import { useToken } from "src/app/hooks";
import PermissionsSelect from "src/features/settings/components/SettingsView/GeneralSettings/Users/PermissionsSelect";

const Users: React.FC = () => {
  const token = useToken();
  const { data: users } = useUsersListGet({
    token,
  });
  const [editUser] = useEditUserPost();

  const handleRowUpdate = async (user: User): Promise<any> => {
    editUser({
      token,
      user,
    });
  };

  if (!users) return null;

  return (
    <Table
      title="משתמשים"
      columns={columns}
      data={users}
      pageSize={5}
      // FEATURE TOGGLE - TODO: remove this feature toggle
      // onRowUpdate={handleRowUpdate}
    />
  );
};

export default Users;

const columns: Column<User>[] = [
  {
    title: "שם משתמש",
    field: "username",
    type: "string",
    width: "20%",
    // FEATURE TOGGLE - TODO: remove this feature toggle
    render: (user) => getDemoUserName(user.username),
  },
  {
    title: "הרשאות",
    field: "permission",
    type: "string",
    width: "50%",
    render: (user) => (
      <PermissionsSelect permissions={user.permission} disabled />
    ),
    editComponent: (props) => (
      <PermissionsSelect permissions={props.value} onChange={props.onChange} />
    ),
  },
  {
    title: "סיסמה",
    field: "password",
    type: "string",
    width: "20%",
    // FEATURE TOGGLE - TODO: add password field to edit user form
    render: (user) => "******",
  },
];

const getDemoUserName = (username: string): string => {
  switch (username) {
    case "user1":
      return "admin-user";
    case "user3":
      return "students-user";

    default:
      return username;
  }
};
